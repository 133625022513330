import React, { useState, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import {
  Button,
  Card,
  List,
  Popconfirm,
  Space,
  Typography,
  Avatar,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import message from '../../../elements/lib/MessageWrapper';
import Format from '../../../../../lib/Format';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import useFetchSignedUrl from '../../../hooks/useFetchSignedUrl';

const { Title, Text } = Typography;

const InvitationItem = ({
  item,
  respond,
  load,
  loadCareGuides,
  page,
  limit,
  order,
  getSignedUrl,
  imageUrl,
  setActiveImage,
  size,
  imagePath,
  ...props
}) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const { width } = useWindowDimensions();
  const isSmall = width <= 576;

  const onClickAcceptHandler = (id) => {
    setIsAccepting(true);
    respond(id, { public_status: 1 }, (success) => {
      if (success) {
        message.success(
          getI18n().t('care_guide_invitations_feedback_accept_success')
        );
        load(page, limit, order);
        loadCareGuides(1, 10, 'started_at.asc');
      } else {
        message.error(
          getI18n().t('care_guide_invitations_feedback_accept_error')
        );
        setIsAccepting(false);
      }
    });
  };

  const onClickRejectHandler = (id) => {
    setIsRejecting(true);
    respond(id, { public_status: 2 }, (success) => {
      if (success) {
        message.success(
          getI18n().t('care_guide_invitations_feedback_reject_success')
        );
        load(page, limit, order);
      } else {
        message.error(
          getI18n().t('care_guide_invitations_feedback_reject_error')
        );
        setIsRejecting(false);
      }
    });
  };

  useFetchSignedUrl(imagePath, imageUrl, getSignedUrl, setActiveImage);

  return (
    <Translation>
      {(t) => (
        <div className={`invitations-list-item`}>
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} src={imageUrl} />}
              title={
                <Text strong>
                  {t('care_guide_invitations_name', {
                    name:
                      item.care_guide.first_name +
                      ' ' +
                      item.care_guide.last_name,
                  })}
                </Text>
              }
              description={
                <div
                  style={
                    !isSmall
                      ? {
                          display: 'flex',
                          justifyContent: 'space-between',
                        }
                      : {}
                  }
                >
                  {t('care_guide_invitations_expires', {
                    date: Format.date(
                      item.expires_at,
                      Config.get('DEFAULT_DATE_FORMAT')
                    ),
                  })}
                  <Space
                    style={{
                      marginRight: '10px',
                      marginTop: isSmall ? '8px' : '0px',
                    }}
                  >
                    <Button
                      key='accept'
                      loading={isAccepting}
                      disabled={isAccepting || isRejecting}
                      onClick={() => onClickAcceptHandler(item.id)}
                      size='small'
                      type='primary'
                      style={{ borderRadius: '2px' }}
                      icon={<CheckCircleOutlined />}
                    >
                      {t('care_guide_invitations_btn_accecpt')}
                    </Button>
                    <Popconfirm
                      key='reject-confirm'
                      placement='topRight'
                      title={t('care_guide_invitations_reject_confirm_body')}
                      onConfirm={() => onClickRejectHandler(item.id)}
                      okText={t('confirm_yes')}
                      cancelText={t('confirm_cancel')}
                      disabled={isAccepting || isRejecting}
                    >
                      <Button
                        icon={<CloseCircleOutlined />}
                        danger
                        loading={isRejecting}
                        disabled={isAccepting || isRejecting}
                        size='small'
                        type='primary'
                        style={{ borderRadius: '2px' }}
                      >
                        {t('care_guide_invitations_btn_reject')}
                      </Button>
                    </Popconfirm>
                  </Space>
                </div>
              }
            />
          </List.Item>
        </div>
      )}
    </Translation>
  );
};

const DashboardInvitationsList = ({
  component,
  page,
  limit,
  order,
  total,
  load,
  respond,
  loadCareGuides,
  ...props
}) => {
  useEffect(() => {
    load(page, limit, order);
  }, [page, limit, order, load]);

  return (
    <Translation>
      {(t) => (
        <>
          {total > 0 ? (
            <Card bordered={false} className='dashboard-invitations-list'>
              <Title level={4}>{t('dashboard_invitations_list_title')}</Title>
              <List
                className='invitations-list'
                itemLayout='horizontal'
                dataSource={props.list}
                renderItem={(item) => (
                  <InvitationItem
                    item={item}
                    respond={respond}
                    load={load}
                    loadCareGuides={loadCareGuides}
                    page={page}
                    limit={limit}
                    order={order}
                  />
                )}
              />
            </Card>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default DashboardInvitationsList;

Logger.log('silly', `DashboardInvitationsList loaded.`);
