import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useFetchSignedUrl from '../../hooks/useFetchSignedUrl';

// ant design primary colors
const colors = ['#F79221', '#BD4242', '#10A64F', '#9D2AC3'];

// param defaultOutput: initials | icon
const UserAvatar = ({
  profile,
  showTooltp = true,
  defaultOutput = 'initials',
  key = null,
  getSignedUrl,
  imageUrl,
  setActiveImage,
  size,
  imagePath,
  ...props
}) => {
  const name = profile ? profile.first_name + ' ' + profile.last_name : null;
  const initials = profile ? (
    profile.first_name.substring(0, 1).toUpperCase() +
    profile.last_name.substring(0, 1).toUpperCase()
  ) : (
    <UserOutlined />
  );

  useFetchSignedUrl(imagePath, imageUrl, getSignedUrl, setActiveImage);

  const icon = imageUrl ? null : defaultOutput === 'icon' ? (
    <UserOutlined />
  ) : (
    initials
  );

  // generate a color for the avatar based on the profile id
  const color = profile
    ? colors[parseInt('0x' + profile.id.substring(0, 4)) % colors.length]
    : colors[0];

  if (showTooltp && name) {
    return (
      <Tooltip key={key} title={name} placement='top'>
        <Avatar
          src={imageUrl}
          icon={icon}
          size={size}
          style={{ backgroundColor: color }}
        />
      </Tooltip>
    );
  } else {
    return (
      <Avatar
        key={key}
        src={imageUrl}
        icon={icon}
        size={size}
        style={{ backgroundColor: color }}
      />
    );
  }
};

export default UserAvatar;
