import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  IMAGE_UPLOAD_URL_REQUEST: 'MEDIA/IMAGE_UPLOAD_URL_REQUEST',
  IMAGE_UPLOAD_URL_SUCCESS: 'MEDIA/IMAGE_UPLOAD_URL_SUCCESS',
  IMAGE_UPLOAD_URL_FAILURE: 'MEDIA/IMAGE_UPLOAD_URL_FAILURE',
  IMAGE_SIGNED_URL_REQUEST: 'MEDIA/IMAGE_SIGNED_URL_REQUEST',
  IMAGE_SIGNED_URL_SUCCESS: 'MEDIA/IMAGE_SIGNED_URL_SUCCESS',
  IMAGE_SIGNED_URL_FAILURE: 'MEDIA/IMAGE_UPLOAD_URL_FAILURE',
  IMAGE_UPLOAD_REQUEST: 'MEDIA/IMAGE_UPLOAD_REQUEST',
  IMAGE_UPLOAD_SUCCESS: 'MEDIA/IMAGE_UPLOAD_SUCCESS',
  IMAGE_UPLOAD_FAILURE: 'MEDIA/IMAGE_UPLOAD_FAILURE',
  CREATE_IMAGE_REQUEST: 'MEDIA/CREATE_IMAGE_REQUEST',
  CREATE_IMAGE_SUCCESS: 'MEDIA/CREATE_IMAGE_SUCCESS',
  CREATE_IMAGE_FAILURE: 'MEDIA/CREATE_IMAGE_FAILURE',
  CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST:
    'MEDIA/CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST',
  CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS:
    'MEDIA/CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS',
  CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE:
    'MEDIA/CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE',
  CREATE_IMAGE_PARTNERSHIP_REQUEST: 'MEDIA/CREATE_IMAGE_PARTNERSHIP_REQUEST',
  CREATE_IMAGE_PARTNERSHIP_SUCCESS: 'MEDIA/CREATE_IMAGE_PARTNERSHIP_SUCCESS',
  CREATE_IMAGE_PARTNERSHIP_FAILURE: 'MEDIA/CREATE_IMAGE_PARTNERSHIP_FAILURE',
  DELETE_IMAGE_REQUEST: 'MEDIA/DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS: 'MEDIA/DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE: 'MEDIA/DELETE_IMAGE_FAILURE',
  DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST:
    'MEDIA/DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST',
  DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS:
    'MEDIA/DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS',
  DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE:
    'MEDIA/DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE',
  DELETE_IMAGE_PARTNERSHIP_REQUEST: 'MEDIA/DELETE_IMAGE_PARTNERSHIP_REQUEST',
  DELETE_IMAGE_PARTNERSHIP_SUCCESS: 'MEDIA/DELETE_IMAGE_PARTNERSHIP_SUCCESS',
  DELETE_IMAGE_PARTNERSHIP_FAILURE: 'MEDIA/DELETE_IMAGE_PARTNERSHIP_FAILURE',
  SET_ACTIVE_IMAGE: 'MEDIA/SET_ACTIVE_IMAGE',
};

export function setActiveImage(imageUrl, imagePath) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideImage(${imageUrl}, ${imagePath})`
  );
  return {
    type: TYPES.SET_ACTIVE_IMAGE,
    imageUrl: imageUrl,
    imagePath: imagePath,
  };
}

export function mediaImageUploadURLRequest(extension, directory) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageUploadURLRequest(${extension}, ${directory})`
  );
  return {
    type: TYPES.IMAGE_UPLOAD_URL_REQUEST,
    extension: extension,
    directory: directory,
  };
}

export function mediaImageUploadURLSuccess(data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageUploadURLSuccess(%j)`,
    data
  );
  return {
    type: TYPES.IMAGE_UPLOAD_URL_SUCCESS,
    upload_url: data.upload_url,
    file_id: data.file_id,
    receivedAt: Date.now(),
  };
}

export function mediaImageUploadURLFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageUploadURLFailure(%j)`,
    error
  );
  return {
    type: TYPES.IMAGE_UPLOAD_URL_FAILURE,
    error: error,
  };
}

export function mediaImageSignedURLRequest(path) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageSignedURLRequest(${path})`
  );
  return {
    type: TYPES.IMAGE_SIGNED_URL_REQUEST,
    path: path,
  };
}

export function mediaImageSignedURLSuccess(data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageSignedURLSuccess(%j)`,
    data
  );
  return {
    type: TYPES.IMAGE_SIGNED_URL_SUCCESS,
    signed_url: data.signed_url,
    receivedAt: Date.now(),
  };
}

export function mediaImageSignedURLFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageSignedURLFailure(%j)`,
    error
  );
  return {
    type: TYPES.IMAGE_SIGNED_URL_FAILURE,
    error: error,
  };
}

export function mediaImageUploadRequest() {
  Logger.log('debug', `[state.media.actions] mediaImageUploadRequest()`);
  return {
    type: TYPES.IMAGE_UPLOAD_REQUEST,
  };
}

export function mediaImageUploadSuccess() {
  Logger.log('debug', `[state.media.actions] mediaImageUploadSuccess()`);
  return {
    type: TYPES.IMAGE_UPLOAD_SUCCESS,
  };
}

export function mediaImageUploadFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageUploadFailure(%j)`,
    error
  );
  return {
    type: TYPES.IMAGE_UPLOAD_FAILURE,
    error: error,
  };
}

export function mediaImageCreateRequest(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCreateRequest(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_IMAGE_REQUEST,
    careGuideId: careGuideId,
  };
}

export function mediaImageCreateSuccess(data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCreateSuccess(%j)`,
    data
  );
  return {
    type: TYPES.CREATE_IMAGE_SUCCESS,
    careGuideId: data.careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function mediaImageCreateFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCreateFailure(%j)`,
    error
  );
  return {
    type: TYPES.CREATE_IMAGE_FAILURE,
    error: error,
  };
}

export function mediaImageCareGuidePartnershipCreateRequest(
  careGuideId,
  partnershipId,
  data
) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCareGuidePartnershipCreateRequest(${careGuideId}, ${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST,
    careGuideId: careGuideId,
    partnershipId: partnershipId,
  };
}

export function mediaImageCareGuidePartnershipCreateSuccess(data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCareGuidePartnershipCreateSuccess(%j)`,
    data
  );
  return {
    type: TYPES.CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS,
    careGuideId: data.careGuideId,
    partnershipId: data.partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function mediaImageCareGuidePartnershipCreateFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCareGuidePartnershipCreateFailure(%j)`,
    error
  );
  return {
    type: TYPES.CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE,
    error: error,
  };
}

export function mediaImagePartnershipCreateRequest(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImagePartnershipCreateRequest(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_IMAGE_PARTNERSHIP_REQUEST,
    partnershipId: partnershipId,
  };
}

export function mediaImagePartnershipCreateSuccess(data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImagePartnershipCreateSuccess(%j)`,
    data
  );
  return {
    type: TYPES.CREATE_IMAGE_PARTNERSHIP_SUCCESS,
    partnershipId: data.partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function mediaImagePartnershipCreateFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImagePartnershipCreateFailure(%j)`,
    error
  );
  return {
    type: TYPES.CREATE_IMAGE_PARTNERSHIP_FAILURE,
    error: error,
  };
}

export function mediaImageDeleteRequest(careGuideId, id) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageDeleteRequest(${careGuideId}, ${id})`
  );
  return {
    type: TYPES.DELETE_IMAGE_REQUEST,
    careGuideId: careGuideId,
    id: id,
  };
}

export function mediaImageDeleteSuccess(data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageDeleteSuccess(%j)`,
    data
  );
  return {
    type: TYPES.DELETE_IMAGE_SUCCESS,
    careGuideId: data.careGuideId,
    id: data.id,
  };
}

export function mediaImageDeleteFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageDeleteFailure(%j)`,
    error
  );
  return {
    type: TYPES.DELETE_IMAGE_FAILURE,
    error: error,
  };
}

export function mediaImageCareGuidePartnershipDeleteRequest(
  careGuideId,
  partnershipId,
  id
) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCareGuidePartnershipDeleteRequest(${careGuideId}, ${partnershipId}, ${id})`
  );
  return {
    type: TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST,
    careGuideId: careGuideId,
    partnershipId: partnershipId,
    id: id,
  };
}

export function mediaImageCareGuidePartnershipDeleteSuccess(data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCareGuidePartnershipDeleteSuccess(%j)`,
    data
  );
  return {
    type: TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS,
    careGuideId: data.careGuideId,
    partnershipId: data.partnershipId,
    id: data.id,
  };
}

export function mediaImageCareGuidePartnershipDeleteFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImageCareGuidePartnershipDeleteFailure(%j)`,
    error
  );
  return {
    type: TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE,
    error: error,
  };
}

export function mediaImagePartnershipDeleteRequest(partnershipId, id) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImagePartnershipDeleteRequest(${partnershipId}, ${id})`
  );
  return {
    type: TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST,
    partnershipId: partnershipId,
    id: id,
  };
}

export function mediaImagePartnershipDeleteSuccess(data) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImagePartnershipDeleteSuccess(%j)`,
    data
  );
  return {
    type: TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS,
    partnershipId: data.partnershipId,
    id: data.id,
  };
}

export function mediaImagePartnershipDeleteFailure(error) {
  Logger.log(
    'debug',
    `[state.media.actions] mediaImagePartnershipDeleteFailure(%j)`,
    error
  );
  return {
    type: TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function getImageUploadURL(extension, directory, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.media.actions] getImageUploadURL(${extension}, ${directory})`
  );

  return async function (dispatch) {
    dispatch(mediaImageUploadURLRequest(extension, directory));

    // call API
    const response = await api.getMediaImageUploadURL(extension, directory);
    let success = false;
    const imageUploadUrlSuccessData = {
      upload_url: null,
      file_id: null,
    };

    // get image upload url success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API image upload URL success.`);

      success = true;
      imageUploadUrlSuccessData.upload_url = response.getIn([
        'data',
        'upload_url',
      ]);
      imageUploadUrlSuccessData.file_key = response.getIn(['data', 'file_key']);

      dispatch(mediaImageUploadURLSuccess(imageUploadUrlSuccessData));

      // get image upload url failure
    } else {
      Logger.log('info', `Get API image upload URL failure.`);
      dispatch(mediaImageUploadURLFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(
      success,
      imageUploadUrlSuccessData.upload_url,
      imageUploadUrlSuccessData.file_key
    );
  };
}

export function getImageSignedURL(path, cb = function () {}) {
  Logger.log('debug', `[state.media.actions] getImageSignedURL(${path})`);

  return async function (dispatch) {
    dispatch(mediaImageSignedURLRequest(path));

    // call API
    const response = await api.getMediaImageSignedURL(path);
    let success = false;
    const imageUploadUrlSuccessData = {
      signed_url: null,
    };

    // get image signed url success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API image signed URL success.`);

      success = true;
      imageUploadUrlSuccessData.signed_url = response.getIn([
        'data',
        'signed_url',
      ]);

      dispatch(mediaImageSignedURLSuccess(imageUploadUrlSuccessData));

      // get image signed url failure
    } else {
      Logger.log('info', `Get API image signed URL failure.`);
      dispatch(mediaImageSignedURLFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, imageUploadUrlSuccessData.signed_url);
  };
}

export function uploadImage(path, file, cb = function () {}) {
  Logger.log('debug', `[state.media.actions] uploadImage(${path}, ###, ###)`);

  return async function (dispatch) {
    dispatch(mediaImageUploadRequest());

    // call API
    const response = await api.putImageUpload(path, file);
    let success = false;

    // put image success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT S3 image success.`);
      success = true;
      dispatch(mediaImageUploadSuccess());

      // put image failure
    } else {
      Logger.log('info', `PUT S3 image failure.`);
      dispatch(mediaImageUploadFailure('Upload error.'));
    }

    // callback function
    cb(success);
  };
}

export function createMediaImage(careGuideId, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.media.actions] createMediaImage(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(mediaImageDeleteRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideMediaImage(careGuideId, data);
    let success = false;
    let id = null;

    // post media image success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API media image success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'media'])],
        [schema.medium]
      );

      const data = {
        careGuideId: careGuideId,
        id: response.getIn(['data', 'media', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaImageCreateSuccess(data));
      success = true;
      id = data.id;

      // post media image failure
    } else {
      Logger.log(
        'info',
        `POST API media image failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(mediaImageCreateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, id);
  };
}

export function createMediaImageCareGuidePartnership(
  careGuideId,
  partnershipId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.media.actions] createMediaImageCareGuidePartnership(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(
      mediaImageCareGuidePartnershipCreateRequest(
        careGuideId,
        partnershipId,
        data
      )
    );

    // call API
    const response = await api.postCareGuidePartnershipMediaImage(
      careGuideId,
      partnershipId,
      data
    );
    let success = false;
    let id = null;

    // post media image success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API media image success. Care Guide ID: ${careGuideId}, Partnership ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'media'])],
        [schema.medium]
      );

      const data = {
        careGuideId: careGuideId,
        partnershipId: partnershipId,
        id: response.getIn(['data', 'media', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaImageCareGuidePartnershipCreateSuccess(data));
      success = true;
      id = data.id;

      // post media image failure
    } else {
      Logger.log(
        'info',
        `POST API media image failure. Care Guide ID: ${careGuideId}, Partnership ID: ${partnershipId}.`
      );
      dispatch(
        mediaImageCareGuidePartnershipCreateFailure(
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, id);
  };
}

export function createMediaImagePartnership(
  partnershipId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.media.actions] createMediaImagePartnership(${partnershipId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(mediaImagePartnershipCreateRequest(partnershipId, data));

    // call API
    const response = await api.postPartnershipMediaImage(partnershipId, data);
    let success = false;
    let id = null;

    // post media image success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API media image success. Partnership ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'media'])],
        [schema.medium]
      );

      const data = {
        partnershipId: partnershipId,
        id: response.getIn(['data', 'media', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(mediaImagePartnershipCreateSuccess(data));
      success = true;
      id = data.id;

      // post media image failure
    } else {
      Logger.log(
        'info',
        `POST API media image failure. Partnership ID: ${partnershipId}.`
      );
      dispatch(
        mediaImagePartnershipCreateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success, id);
  };
}

export function deleteMediaImage(careGuideId, id, cb = function () {}) {
  Logger.log('debug', `[state.media.actions] deleteMediaImage(${id}, ###)`);

  return async function (dispatch) {
    dispatch(mediaImageDeleteSuccess(careGuideId, id));

    // call API
    const response = await api.deleteCareGuideMediaImage(careGuideId, id);
    let success = false;

    // delete media image success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API media image success. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'media', id: id }));
      dispatch(mediaImageDeleteSuccess({ careGuideId: careGuideId, id: id }));
      success = true;

      // get media image failure
    } else {
      Logger.log(
        'info',
        `DELETE API media image failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        mediaImageDeleteFailure(careGuideId, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteMediaImageCareGuidePartnership(
  careGuideId,
  partnershipId,
  id,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.media.actions] deleteMediaImageCareGuidePartnership(${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(
      mediaImageCareGuidePartnershipDeleteSuccess(
        careGuideId,
        partnershipId,
        id
      )
    );

    // call API
    const response = await api.deleteCareGuidePartnershipMediaImage(
      careGuideId,
      partnershipId,
      id
    );
    let success = false;

    // delete media image success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API media image success. Care Guide ID: ${careGuideId}, Partnership ID: ${partnershipId}, ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'media', id: id }));
      dispatch(
        mediaImageCareGuidePartnershipDeleteSuccess({
          careGuideId: careGuideId,
          partnershipId: partnershipId,
          id: id,
        })
      );
      success = true;

      // get media image failure
    } else {
      Logger.log(
        'info',
        `DELETE API media image failure. Care Guide ID: ${careGuideId}, Partnership ID: ${partnershipId}, ID: ${id}.`
      );
      dispatch(
        mediaImageCareGuidePartnershipDeleteFailure(
          careGuideId,
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteMediaImagePartnership(
  partnershipId,
  id,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.media.actions] deleteMediaImagePartnership(${partnershipId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(mediaImagePartnershipDeleteSuccess(partnershipId, id));

    // call API
    const response = await api.deletePartnershipMediaImage(partnershipId, id);
    let success = false;

    // delete media image success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API media image success. Partnership ID: ${partnershipId}, ID: ${id}.`
      );

      dispatch(removeEntity({ entityType: 'media', id: id }));
      dispatch(
        mediaImagePartnershipDeleteSuccess({
          partnershipId: partnershipId,
          id: id,
        })
      );
      success = true;

      // get media image failure
    } else {
      Logger.log(
        'info',
        `DELETE API media image failure. Partnership ID: ${partnershipId}, ID: ${id}.`
      );
      dispatch(
        mediaImagePartnershipDeleteFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.media.actions loaded.`);
