import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import CareGuidePicker from '../components/CareGuidePicker';
import { schema } from '../../../../../state/schema';
import { loadCareGuides } from '../../../../../state/modules/careGuides/actions';

import { getImageSignedURL } from '../../../../../state/modules/media/actions';

const mapStateToProps = (state, ownProps) => {
  const page = 1;
  const limit = 10;
  const order = 'started_at.asc';
  const result = state.careGuides.getIn(['pages', order, limit, page]);

  let defaultId = ownProps.careGuideId || state.careGuides.get('activeId');
  const defaultLabel = defaultId
    ? state.entities.getIn(['careGuides', defaultId, 'first_name'], '') +
      ' ' +
      state.entities.getIn(['careGuides', defaultId, 'last_name'], '')
    : null;

  const defaultImage = defaultId
    ? state.entities.getIn(['careGuides', defaultId, 'image'])
    : null;

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuides', x]),
                schema.careGuide,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    total: state.careGuides.get('total'),
    isLoading: state.careGuides.get('isLoading'),
    defaultId: defaultId,
    defaultLabel: defaultLabel,
    defaultImage: defaultImage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, partnershipId, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, partnershipId, cb));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
  };
};

const CareGuidePickerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuidePicker);

export default CareGuidePickerContainer;
