import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card, List } from 'antd';

import { pathTo } from '../../../Routes';
import UserAvatar from '../../../elements/containers/UserAvatarContainer';
import LoadingIcon from '../../../elements/components/LoadingIcon';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import EventModal from '../containers/EventModalContainer';

const EventList = ({
  careGuideId,
  component,
  start,
  end,
  page,
  limit,
  order,
  total,
  load,
  isLoading,
  showDetail,
  ...props
}) => {
  useEffect(() => {
    load(careGuideId, start, end, page, limit, order);
  }, [careGuideId, start, end, page, limit, order, load]);

  const onClick = (id, view) => {
    showDetail(id, view);
  };

  return (
    <Translation>
      {(t) => (
        <>
          {total > 0 ? (
            <>
              <Card
                title={t('dashboard_calendar_event_list_title')}
                extra={isLoading ? <LoadingIcon size={16} /> : null}
                size='small'
                bordered={false}
              >
                <List
                  className='calendar-events-list'
                  itemLayout='horizontal'
                  size='small'
                  dataSource={props.list}
                  renderItem={(item) => (
                    <List.Item
                      className='cursor-pointer'
                      key={item.id}
                      onClick={(e) => onClick(item.id, true)}
                    >
                      <List.Item.Meta
                        title={item.title}
                        description={
                          item.is_all_day
                            ? Format.date(item.start_at, 'ddd, MMM D') // all day event
                            : Format.date(item.start_at, 'YYYY-MM-DD') ===
                              Format.date(item.end_at, 'YYYY-MM-DD')
                            ? Format.date(item.start_at, 'ddd, MMM D h:mm a') // event occurs on a single day
                            : Format.date(item.start_at, 'YYYY-MM') ===
                              Format.date(item.end_at, 'YYYY-MM')
                            ? Format.date(item.start_at, 'MMM D') +
                              '-' +
                              Format.date(item.end_at, 'D') // event occurs over multiple days, same month
                            : Format.date(item.start_at, 'MMM D') +
                              '-' +
                              Format.date(item.end_at, 'MMM D') // event occurs over multiple days, different month
                        }
                      />

                      {item.care_guide_id == null ? (
                        <UserAvatar profile={item.profile} />
                      ) : (
                        <div className='event-content'>
                          <Avatar.Group
                            maxCount={3}
                            maxStyle={{
                              color: '#f56a00',
                              backgroundColor: '#fde3cf',
                            }}
                          >
                            {item.participants
                              ? item.participants.map((x, i) => (
                                  <UserAvatar key={i} profile={x.profile} />
                                ))
                              : null}
                          </Avatar.Group>
                        </div>
                      )}
                    </List.Item>
                  )}
                />
                <Button>
                  <Link to={pathTo('CalendarScreen', { id: careGuideId })}>
                    {t('dashboard_calendar_btn_view_calendar')}
                  </Link>
                </Button>
              </Card>
              <EventModal />
            </>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default EventList;

Logger.log('silly', `EventList loaded.`);
