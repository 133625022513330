import { connect } from 'react-redux';

import {
  getImageUploadURL,
  getImageSignedURL,
  uploadImage,
  setActiveImage,
} from '../../../../../state/modules/media/actions';
import {
  updatePartnershipAccountLogo,
  deletePartnershipAccountLogo,
} from '../../../../../state/modules/partnershipAccount/actions';
import storage from '../../../../../lib/Storage';
import Format from '../../../../../lib/Format';

import PartnershipLogoForm from '../components/PartnershipLogoForm';

const mapStateToProps = (state, ownProps) => {
  const logo = state.partnershipAccount.getIn(['data', 'logo']);
  const size = 102;

  const imagePath = Format.buildImagePath('ratio', size, size, logo);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    size: size,
    imagePath: imagePath,
    imageUrl: imageUrl,
    activeId: storage.get('local', 'partnershipId'),
    isLoading: state.media.get('isImageUploadUrlLoading'),
    isUploading: state.media.get('isImageUploading'),
    isProcessing: state.partnershipAccount.get('isSubmittingLogo'),
    isSubmitting:
      state.media.get('isImageUploadUrlLoading') ||
      state.media.get('isImageUploading') ||
      state.partnershipAccount.get('isSubmittingLogo'),
    upload_url: state.media.getIn(['upload', 'upload_url']),
    file_id: state.media.getIn(['upload', 'file_id']),
    isDeleting: state.partnershipAccount.get('isDeletingLogo'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    update: (partnership_id, data, cb) => {
      dispatch(updatePartnershipAccountLogo(partnership_id, data, cb));
    },
    delete: (partnership_id, cb) => {
      dispatch(deletePartnershipAccountLogo(partnership_id, cb));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const PartnershipLogoFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipLogoForm);

export default PartnershipLogoFormContainer;
