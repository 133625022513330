import { connect } from 'react-redux';

import {
  getImageUploadURL,
  getImageSignedURL,
  uploadImage,
  setActiveImage,
} from '../../../../../state/modules/media/actions';
import {
  updateCareGuideImage,
  deleteCareGuideImage,
} from '../../../../../state/modules/careGuides/actions';
import CareGuideImageForm from '../components/CareGuideImageForm';
import Format from '../../../../../lib/Format';

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;
  const image = state.entities.getIn(['careGuides', careGuideId, 'image']);
  const size = 160;

  const imagePath = Format.buildImagePath('crop', size, size, image);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    size: size,
    imagePath: imagePath,
    isLoading: state.media.get('isImageUploadUrlLoading'),
    isUploading: state.media.get('isImageUploading'),
    isProcessing: state.careGuides.get('isSubmittingImage'),
    isDeleting: state.careGuides.get('isDeletingImage'),
    imageUrl: imageUrl,
    isSubmitting:
      state.media.get('isImageUploadUrlLoading') ||
      state.media.get('isImageUploading') ||
      state.careGuides.get('isSubmittingImage'),
    upload_url: state.media.getIn(['upload', 'upload_url']),
    file_id: state.media.getIn(['upload', 'file_id']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    update: (careGuideId, data, cb) => {
      dispatch(updateCareGuideImage(careGuideId, data, cb));
    },
    delete: (careGuideId, cb) => {
      dispatch(deleteCareGuideImage(careGuideId, cb));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const CareGuideImageFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideImageForm);

export default CareGuideImageFormContainer;
