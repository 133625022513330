import { connect } from 'react-redux';
import {
  getImageSignedURL,
  setActiveImage,
} from '../../../../state/modules/media/actions';

import UserAvatar from '../components/UserAvatar';
import Format from '../../../../lib/Format';

const mapStateToProps = (state, ownProps) => {
  const avatar = ownProps.profile?.avatar ?? null;
  const size = ownProps.size ?? 32;

  const imagePath = Format.buildImagePath('crop', size, size, avatar);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    size: size,
    imagePath: imagePath,
    imageUrl: imageUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const UserAvatarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAvatar);

export default UserAvatarContainer;
