import { connect } from 'react-redux';
import { schema } from '../../../../../state/schema';
import { denormalize } from 'normalizr';

import {
  resourceAdminHidePreviewDetail,
  loadResourceAdmin,
} from '../../../../../state/modules/resourcesAdmin/actions';
import {
  getImageSignedURL,
  setActiveImage,
} from '../../../../../state/modules/media/actions';

import ResourcePreview from '../components/ResourcePreview';
import storage from '../../../../../lib/Storage';
import { resourceNoteShowListAdmin } from '../../../../../state/modules/resourceNotes/actions';
import Format from '../../../../../lib/Format';

const mapStateToProps = (state, ownProps) => {
  const resourceId = state.resourcesAdmin.get('resourceDetailResourceId');

  const resource = resourceId
    ? denormalize(
        state.entities.getIn(['resourcesAdmin', resourceId]),
        schema.resourceAdmin,
        state.entities.toJS()
      )
    : null;

  const logo = resource?.logo ?? null;
  const sizeWidth = 145;
  const sizeHeight = 35;

  const imagePath = Format.buildImagePath('ratio', sizeWidth, sizeHeight, logo);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    sizeWidth: sizeWidth,
    sizeHeight: sizeHeight,
    imagePath: imagePath,
    imageUrl: imageUrl,
    visible: state.resourcesAdmin.get('resourceDetailIsVisible'),
    resourceId: resourceId,
    resource: resource,
    activeId: storage.get('local', 'partnershipId'),
    partnershipActiveId: storage.get('local', 'partnershipId'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideDetail: () => {
      dispatch(resourceAdminHidePreviewDetail());
    },
    load: (partnership_id, id, cb) => {
      dispatch(loadResourceAdmin(partnership_id, id, cb));
    },
    showList: (resourceId) => {
      dispatch(resourceNoteShowListAdmin(resourceId));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const ResourcePreviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcePreview);

export default ResourcePreviewContainer;
