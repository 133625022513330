import React from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Col, Row, Space, Upload } from 'antd';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';
import useFetchSignedUrl from '../../../hooks/useFetchSignedUrl';

const PartnershipLogoForm = ({
  getSignedUrl,
  imageUrl,
  setActiveImage,
  size,
  imagePath,
  ...props
}) => {
  // upload criteria
  const fileTypes = {
    'image/png': 'png',
    'image/jpeg': 'jpg',
  };
  const fileSizeLimit = 1024 * 1024 * 4;

  const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;

  const beforeUpload = (file) => {
    const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
    if (!isSupportedFileType) {
      message.error(getI18n().t('partnership_logo_form_feedback_file_types'));
    }

    const isUnderFileSizeLimit = file.size < fileSizeLimit;
    if (!isUnderFileSizeLimit) {
      message.error(
        getI18n().t('partnership_logo_form_feedback_file_size_limit')
      );
    }

    return isSupportedFileType && isUnderFileSizeLimit;
  };

  const uploadButton = (
    <Translation>
      {(t) => (
        <div>
          {props.isSubmitting ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>{t('action_upload')}</div>
        </div>
      )}
    </Translation>
  );

  const errorMessage = () => {
    message.error(getI18n().t('partnership_logo_form_feedback_error'));
  };

  const submit = async (data) => {
    // get upload URL for S3 bucket
    props.getUploadUrl(
      fileTypes[data.file.type],
      'partnership-logos',
      async (success, upload_url, file_id) => {
        if (success) {
          // upload image
          props.upload(upload_url, data.file, async (uploadSuccess) => {
            const directory = 'partnership-logos/';
            file_id = file_id.replace(directory, '');
            if (uploadSuccess) {
              const matches = reFileKey.exec(file_id);
              const updateData = {
                file_directory: directory.slice(0, -1),
                file_name: matches[2],
                file_extension: matches[3],
              };

              // process image
              props.update(props.activeId, updateData, (updateSuccess) => {
                if (updateSuccess) {
                  message.success(
                    getI18n().t('partnership_logo_form_feedback_success')
                  );
                } else {
                  errorMessage();
                }
              });
            } else {
              errorMessage();
            }
          });
        } else {
          errorMessage();
        }
      }
    );
  };

  const onClickDeleteHandler = () => {
    props.delete(props.activeId, (success) => {
      if (success) {
        message.success(
          getI18n().t('partnership_logo_form_feedback_delete_success')
        );
      } else {
        message.error(
          getI18n().t('partnership_logo_form_feedback_delete_error')
        );
      }
    });
  };

  useFetchSignedUrl(imagePath, imageUrl, getSignedUrl, setActiveImage);

  return (
    <Translation>
      {(t) => (
        <div className='partnership-logo-form'>
          <Row>
            <Col>
              <Upload
                name='logo'
                listType='picture-card'
                className='logo-uploader'
                showUploadList={false}
                customRequest={submit}
                beforeUpload={beforeUpload}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt='logo' style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Col>

            <Col>
              <Space>
                <Upload
                  name='logo'
                  showUploadList={false}
                  customRequest={submit}
                  beforeUpload={beforeUpload}
                >
                  <Button
                    icon={<UploadOutlined />}
                    loading={props.isSubmitting}
                  >
                    {t('action_upload')}
                  </Button>
                </Upload>

                <Button
                  icon={<CloseOutlined />}
                  loading={props.isDeleting}
                  onClick={onClickDeleteHandler}
                >
                  {t('action_remove')}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      )}
    </Translation>
  );
};

export default PartnershipLogoForm;

Logger.log('silly', `PartnershipLogoForm loaded.`);
