import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Avatar, Button, Dropdown, Form, Input, Menu } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const CareGuidePicker = ({
  list,
  load,
  defaultId,
  defaultLabel,
  defaultImage,
  onChange,
  disabled = false,
  getSignedUrl,
  ...props
}) => {
  const [imageUrl, setImageUrl] = useState(null);

  const [currentId, setCurrentId] = useState(defaultId);
  const [currentLabel, setCurrentLabel] = useState(defaultLabel);
  const [currentImage, setCurrentImage] = useState(defaultImage);

  useEffect(() => {
    onChange(currentId);
  }, [currentId, onChange]);

  useEffect(() => {
    setCurrentId(defaultId);
    onChange(defaultId);
  }, [defaultId, setCurrentId, onChange]);

  useEffect(() => {
    setCurrentLabel(defaultLabel);
  }, [defaultLabel, setCurrentLabel]);

  useEffect(() => {
    setCurrentImage(defaultImage);
  }, [defaultImage, setCurrentImage]);

  const clickHandler = (e) => {
    const id = e.item.props.value;
    const selectedCareGuide = list.filter((x) => x.id === id)[0];
    setCurrentId(selectedCareGuide.id);
    setCurrentLabel(
      selectedCareGuide.first_name + ' ' + selectedCareGuide.last_name
    );
  };

  const menu = (
    <Menu onClick={clickHandler}>
      {list.map((x) => (
        <Menu.Item key={x.id} value={x.id}>
          {x.first_name + ' ' + x.last_name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const size = 24;
  useEffect(
    () => {
      if (
        currentImage &&
        currentImage?.file_directory &&
        currentImage?.file_name &&
        currentImage?.file_extension
      ) {
        const path = `crop/${size}x${size}/${currentImage.file_directory}/${currentImage.file_name}.${currentImage.file_extension}`;
        getSignedUrl(path, (success, signedUrl) => {
          if (success) {
            setImageUrl(signedUrl);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentImage?.file_directory,
      currentImage?.file_name,
      currentImage?.file_extension,
    ]
  );

  return (
    <Translation>
      {(t) => (
        <>
          {props.total > 0 ? (
            <div className='care-guide-picker'>
              <Avatar size={size} src={imageUrl} icon={<UserOutlined />} />
              {list.length > 1 ? (
                <Dropdown overlay={menu} disabled={disabled}>
                  <Button>
                    {currentLabel} <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button>{currentLabel}</Button>
              )}
            </div>
          ) : null}

          <Form.Item
            name={props.name}
            label={props.label}
            rules={props.rules}
            style={{ display: 'none' }}
          >
            <Input type='hidden' />
          </Form.Item>
        </>
      )}
    </Translation>
  );
};

export default withRouter(CareGuidePicker);
