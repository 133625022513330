import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import DashboardInvitationsList from '../components/DashboardInvitationsList';
import { schema } from '../../../../../state/schema';
import {
  loadInvitations,
  respondCareGuideInvitation,
} from '../../../../../state/modules/careGuideInvitations/actions';
import { loadCareGuides } from '../../../../../state/modules/careGuides/actions';
import {
  getImageSignedURL,
  setActiveImage,
} from '../../../../../state/modules/media/actions';
import Format from '../../../../../lib/Format';

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuideInvitations.getIn([
    'pages',
    order,
    limit,
    page,
  ]);

  const image = ownProps.item?.care_guide?.image ?? null;
  const size = 30;

  const imagePath = Format.buildImagePath('crop', size, size, image);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    size: size,
    imagePath: imagePath,
    imageUrl: imageUrl,
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuideInvitations', x]),
                schema.careGuideInvitation,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    total: state.careGuideInvitations.get('total', 0),
    isLoading: state.careGuideInvitations.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadInvitations(page, limit, order, filter, cb));
    },
    respond: (id, data, cb) => {
      dispatch(respondCareGuideInvitation(id, data, cb));
    },
    loadCareGuides: (page, limit, order, filter, partnershipId, cb) => {
      dispatch(loadCareGuides(page, limit, order, filter, partnershipId, cb));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const DashboardInvitationsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardInvitationsList);

export default DashboardInvitationsListContainer;
