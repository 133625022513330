import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Card, List, Tag } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import UserAvatar from '../../../elements/containers/UserAvatarContainer';
import { TelephoneDisplay } from '../../../elements/components/TelephoneDisplay';
import { MessageLink } from '../../messages/components/MessageLink';
import hasPermission, {
  UPDATE_CARE_GUIDE_HELPER,
} from '../../../elements/lib/hasPermission';
import Config from '../../../../../Config';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';

const HelperCard = ({ item, ...props }) => {
  return (
    <Translation>
      {(t) => (
        <Card>
          <Card.Meta
            avatar={<UserAvatar profile={item.profile} showTooltp={false} />}
            title={
              <>
                {item.profile
                  ? item.profile.first_name + ' ' + item.profile.last_name
                  : null}
                {!item.is_owner && hasPermission(UPDATE_CARE_GUIDE_HELPER) ? (
                  <span className='helper-list-item-menu'>
                    <SettingOutlined onClick={() => props.showForm(item.id)} />
                  </span>
                ) : null}
              </>
            }
            description={
              <>
                {t('care_guide_helpers_role')}:{' '}
                {item.is_owner
                  ? t('care_guide_helpers_role_owner')
                  : t('care_guide_helpers_role_' + item.role)}
                {item.public_status === 2 ? (
                  <Tag>{t('care_guide_helpers_public_status_2')}</Tag>
                ) : null}
                {item.public_status === 3 ? (
                  <Tag>{t('care_guide_helpers_public_status_3')}</Tag>
                ) : null}
              </>
            }
          />
          <ul className='helpers-list-contact-list'>
            {item.profile && item.profile.telephone ? (
              <li>
                <TelephoneDisplay telephone={item.profile.telephone} />
              </li>
            ) : null}
            {item.profile && !item.is_self ? (
              <li>
                <MessageLink
                  profile={item.profile}
                  careGuideId={props.careGuideId}
                />
              </li>
            ) : null}
          </ul>

          <div className='helpers-list-tag-list'>
            {item.tags ? item.tags.map((x, i) => <Tag key={i}>{x}</Tag>) : null}
          </div>
        </Card>
      )}
    </Translation>
  );
};

const InvitationCard = ({ item, ...props }) => {
  const statuses = {
    1: {
      color: 'green',
      label: 'care_guide_invitations_public_status_accepted',
    },
    2: {
      color: 'red',
      label: 'care_guide_invitations_public_status_rejected',
    },
    3: {
      color: 'blue',
      label: 'care_guide_invitations_public_status_pending',
    },
    4: {
      color: 'orange',
      label: 'care_guide_invitations_public_status_expired',
    },
  };

  return (
    <Translation>
      {(t) => (
        <Card>
          <Card.Meta
            avatar={<UserAvatar profile={item.profile} showTooltp={false} />}
            title={
              <>
                {item.invitation ? item.invitation.email : null}
                {!item.is_owner && hasPermission(UPDATE_CARE_GUIDE_HELPER) ? (
                  <span className='helper-list-item-menu'>
                    <SettingOutlined onClick={() => props.showForm(item.id)} />
                  </span>
                ) : null}
              </>
            }
            description={
              <>
                {t('care_guide_helpers_role') +
                  ': ' +
                  t('care_guide_helpers_role_' + item.role)}
                {item.invitation &&
                item.invitation.public_status &&
                item.invitation.public_status in statuses ? (
                  <Tag color={statuses[item.invitation.public_status].color}>
                    {t(statuses[item.invitation.public_status].label)}
                  </Tag>
                ) : null}
              </>
            }
          />
          <ul className='helpers-list-contact-list'>
            {item.invitation && item.invitation.invited_at ? (
              <li>
                {t('care_guide_invitations_invited_at')}:{' '}
                {Format.date(
                  item.invitation.invited_at,
                  Config.get('DEFAULT_DATE_FORMAT')
                )}
              </li>
            ) : null}
            {item.invitation && item.invitation.expires_at ? (
              <li>
                {t('care_guide_invitations_expires_at')}:{' '}
                {Format.date(
                  item.invitation.expires_at,
                  Config.get('DEFAULT_DATE_FORMAT')
                )}
              </li>
            ) : null}
          </ul>
        </Card>
      )}
    </Translation>
  );
};

const CareGuideHelpersList = ({
  careGuideId,
  component,
  parentPage,
  page,
  limit,
  order,
  total,
  load,
  showForm,
  showInvitationForm,
  history,
  ...props
}) => {
  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    position: ['bottomCenter'],
  };

  useEffect(() => {
    load(careGuideId, page, limit, order);
  }, [careGuideId, page, limit, order, load]);

  return (
    <Translation>
      {(t) => (
        <List
          className='helpers-list'
          dataSource={props.list}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          pagination={page === 1 && total <= limit ? false : pagination}
          renderItem={(item) => (
            <List.Item>
              {item.public_status === 3 ? (
                <InvitationCard item={item} showForm={showInvitationForm} />
              ) : (
                <HelperCard
                  item={item}
                  showForm={showForm}
                  careGuideId={careGuideId}
                />
              )}
            </List.Item>
          )}
        />
      )}
    </Translation>
  );
};

export default withRouter(CareGuideHelpersList);

Logger.log('silly', `CareGuideHelpersList loaded.`);
