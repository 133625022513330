import { connect } from 'react-redux';

import {
  getImageUploadURL,
  getImageSignedURL,
  uploadImage,
  setActiveImage,
} from '../../../../../state/modules/media/actions';

import {
  updateUserAccountAvatar,
  deleteUserAccountAvatar,
} from '../../../../../state/modules/userAccount/actions';
import { sendMessage } from '../../../../../state/actions';
import UserAvatarForm from '../components/UserAvatarForm';

import Format from '../../../../../lib/Format';

const mapStateToProps = (state) => {
  const avatar = state.userAccount.getIn(['data', 'avatar']);
  const size = 102;

  const imagePath = Format.buildImagePath('ratio', size, size, avatar);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    size: size,
    imagePath: imagePath,
    imageUrl: imageUrl,
    isLoading: state.media.get('isImageUploadUrlLoading'),
    isUploading: state.media.get('isImageUploading'),
    isProcessing: state.userAccount.get('isSubmittingAvatar'),
    isSubmitting:
      state.media.get('isImageUploadUrlLoading') ||
      state.media.get('isImageUploading') ||
      state.userAccount.get('isSubmittingAvatar'),
    upload_url: state.media.getIn(['upload', 'upload_url']),
    file_id: state.media.getIn(['upload', 'file_id']),
    avatar: avatar,
    isDeleting: state.userAccount.get('isDeletingAvatar'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUploadUrl: (extension, directory, cb) => {
      dispatch(getImageUploadURL(extension, directory, cb));
    },
    upload: (path, file, cb) => {
      dispatch(uploadImage(path, file, cb));
    },
    update: (data, cb) => {
      dispatch(updateUserAccountAvatar(data, cb));
    },
    delete: (cb) => {
      dispatch(deleteUserAccountAvatar(cb));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const UserAvatarFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAvatarForm);

export default UserAvatarFormContainer;
