import { connect } from 'react-redux';
import { schema } from '../../../../../state/schema';
import { denormalize } from 'normalizr';
import {
  getImageSignedURL,
  setActiveImage,
} from '../../../../../state/modules/media/actions';
import {
  resourceHidePreviewDetail,
  loadResource,
  patchResourceFavorite,
  createResourceInteraction,
} from '../../../../../state/modules/resources/actions';
import { resourceNoteShowList } from '../../../../../state/modules/resourceNotes/actions';
import ResourcePreview from '../components/ResourcePreview';
import storage from '../../../../../lib/Storage';
import Format from '../../../../../lib/Format';

const mapStateToProps = (state, ownProps) => {
  const resourceId = state.resources.get('resourceDetailResourceId');
  const careGuideId =
    state.careGuides.get('activeId') ?? ownProps.match.params.id;

  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const resource = resourceId
    ? denormalize(
        state.entities.getIn(['resources', resourceId]),
        schema.resource,
        state.entities.toJS()
      )
    : null;

  const logo = resource?.logo ?? null;

  const sizeWidth = 145;
  const sizeHeight = 35;

  const imagePath = Format.buildImagePath('ratio', sizeWidth, sizeHeight, logo);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    sizeWidth: sizeWidth,
    sizeHeight: sizeHeight,
    imagePath: imagePath,
    imageUrl: imageUrl,
    resource,
    careGuide,
    visible: state.resources.get('resourceDetailIsVisible'),
    resourceId: resourceId,
    careGuideId: careGuideId,
    partnershipId: storage.get('local', 'partnershipId'),
    partnershipName: storage.get('local', 'partnershipData')?.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideDetail: () => {
      dispatch(resourceHidePreviewDetail());
    },
    showList: (resourceId) => {
      dispatch(resourceNoteShowList(resourceId));
    },
    load: (careGuideId, id, cb) => {
      dispatch(loadResource(careGuideId, id, cb));
    },
    patchFavorite: (careGuideId, id, data, cb) => {
      dispatch(patchResourceFavorite(careGuideId, id, data, cb));
    },
    createInteraction: (careGuideId, resourceId, data, cb) => {
      dispatch(createResourceInteraction(careGuideId, resourceId, data, cb));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const ResourcePreviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcePreview);

export default ResourcePreviewContainer;
