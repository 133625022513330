import { connect } from 'react-redux';
import {
  getImageSignedURL,
  setActiveImage,
} from '../../../../../state/modules/media/actions';

import ResourceCard from '../components/ResourceCard';
import Format from '../../../../../lib/Format';

const mapStateToProps = (state, ownProps) => {
  const logo = ownProps.item?.logo ?? null;
  const sizeWidth = 145;
  const sizeHeight = 35;

  const imagePath = Format.buildImagePath('ratio', sizeWidth, sizeHeight, logo);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    sizeWidth: sizeWidth,
    sizeHeight: sizeHeight,
    imagePath: imagePath,
    imageUrl: imageUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const ResourceCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceCard);

export default ResourceCardContainer;
