import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Button, Comment, List, Typography, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';

import UserAvatar from '../../../elements/containers/UserAvatarContainer';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';

const { Title, Text } = Typography;

const ResourceNotesListAdmin = ({
  partnershipId,
  resourceId,
  component,
  page,
  limit,
  order,
  total,
  load,
  history,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (partnershipId && resourceId) {
      load(partnershipId, resourceId, page, limit, order, null, () => {
        setIsLoading(false);
      });
    }
  }, [partnershipId, resourceId, page, limit, order, load]);

  const onLoadMoreClick = () => {
    setIsLoading(true);
    props.setPage(page + 1);
  };

  // using ternary to keep button visible while loading
  const showLoadMore = isLoading
    ? Math.ceil((page - 1) * limit) < total
    : Math.ceil(page * limit) < total;

  return (
    <Translation>
      {(t) => (
        <>
          <Title level={5}>{t('resource_notes_list_subtitle')}</Title>
          {total ? (
            <List
              className='resource-notes-list'
              itemLayout='vertical'
              dataSource={props.list}
              pagination={false}
              renderItem={(item) => {
                return (
                  <List.Item key={item.id}>
                    <Comment
                      author={
                        <Space direction='vertical'>
                          <Space>
                            <Text>
                              {`${item.profile.first_name} ${item.profile.last_name}`}
                            </Text>
                            {item?.care_guide && (
                              <>
                                <Text type='secondary'>{`-`}</Text>
                                <Text type='secondary'>
                                  {`${item.care_guide.first_name} ${item.care_guide.last_name}`}
                                </Text>
                              </>
                            )}
                          </Space>
                        </Space>
                      }
                      avatar={<UserAvatar profile={item.profile} />}
                      content={
                        <div dangerouslySetInnerHTML={{ __html: item.body }} />
                      }
                      datetime={
                        <>
                          {Format.date(
                            item?.published_at,
                            Config.get('DEFAULT_DATETIME_FORMAT')
                          )}
                        </>
                      }
                    />
                  </List.Item>
                );
              }}
            />
          ) : (
            <div className='resource-notes-list-empty'>
              <FontAwesomeIcon icon={faBook} size='2x' />
              <div style={{ marginTop: '10px' }}>
                <Text type='secondary'>{t('resource_notes_list_empty')}</Text>
              </div>
            </div>
          )}

          {showLoadMore ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                size='small'
                onClick={onLoadMoreClick}
                icon={<ReloadOutlined />}
                loading={isLoading}
              >
                {t('action_load_more')}
              </Button>
            </div>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourceNotesListAdmin);

Logger.log('silly', `ResourceNotesListAdmin loaded.`);
