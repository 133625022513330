import { connect } from 'react-redux';
import {
  getImageSignedURL,
  setActiveImage,
} from '../../../../../state/modules/media/actions';

import ResourceCard from '../components/ResourceCard';
import {
  loadResources,
  deleteResource,
  resourceShowForm,
  patchResource,
  patchResourceFavorite,
  resourceShowPreviewDetail,
  createResourceInteraction,
} from '../../../../../state/modules/resources/actions';
import {
  downloadResourceCSVAdmin,
  downloadResourcePDFAdmin,
} from '../../../../../state/modules/resourcesAdmin/actions';
import Format from '../../../../../lib/Format';

import { resourceNoteShowList } from '../../../../../state/modules/resourceNotes/actions';

const mapStateToProps = (state, ownProps) => {
  const logo = ownProps.item?.logo ?? null;
  const sizeWidth = 145;
  const sizeHeight = 35;

  const imagePath = Format.buildImagePath('ratio', sizeWidth, sizeHeight, logo);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    sizeWidth: sizeWidth,
    sizeHeight: sizeHeight,
    imagePath: imagePath,
    imageUrl: imageUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(loadResources(careGuideId, page, limit, order, filter, cb));
    },
    patchFavorite: (careGuideId, id, data, cb) => {
      dispatch(patchResourceFavorite(careGuideId, id, data, cb));
    },
    patch: (careGuideId, id, data, cb) => {
      dispatch(patchResource(careGuideId, id, data, cb));
    },
    remove: (careGuideId, id, cb) => {
      dispatch(deleteResource(careGuideId, id, cb));
    },
    showDetail: (resourceId) => {
      dispatch(resourceShowPreviewDetail(resourceId));
    },
    showList: (resourceId) => {
      dispatch(resourceNoteShowList(resourceId));
    },
    showForm: (id) => {
      dispatch(resourceShowForm(id));
    },
    createInteraction: (careGuideId, resourceId, data, cb) => {
      dispatch(createResourceInteraction(careGuideId, resourceId, data, cb));
    },
    downloadCSV: (partnershipId, partnershipName, resourceId, cb) => {
      dispatch(
        downloadResourceCSVAdmin(partnershipId, partnershipName, resourceId, cb)
      );
    },
    downloadPDF: (partnershipId, partnershipName, resourceId, cb) => {
      dispatch(
        downloadResourcePDFAdmin(partnershipId, partnershipName, resourceId, cb)
      );
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const ResourceCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceCard);

export default ResourceCardContainer;
