import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Modal, Tag, Typography, Divider, Button } from 'antd';
import { Col, Row } from 'antd';
import { TelephoneDisplayAdmin } from '../../../elements/components/TelephoneDisplay';
import { WebsiteDisplayAdmin } from '../../../elements/components/WebsiteDisplay';
import { EmailDisplayAdmin } from '../../../elements/components/EmailDisplay';
import ReactQuill from 'react-quill';
import { EyeOutlined } from '@ant-design/icons';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';
import Logger from '../../../../../lib/Logger';
import useFetchSignedUrl from '../../../hooks/useFetchSignedUrl';

const { Title } = Typography;

const modules = {
  toolbar: '#toolbar-read-only-resource',
};
const types = {
  link: 'link',
  phone: 'phone',
  email: 'email',
};

const ResourcePreviewFotter = ({ resource, showList }) => {
  return (
    <Translation>
      {(t) => (
        <Row align='top' justify='space-between'>
          <Col>
            <Button
              size='small'
              icon={
                <span className='anticon'>
                  <EyeOutlined />
                </span>
              }
              onClick={(e) => {
                e.stopPropagation();
                showList(resource.id);
              }}
              style={{ marginTop: '10px' }}
            >
              {t('resource_notes_button_notes')}
            </Button>
          </Col>
        </Row>
      )}
    </Translation>
  );
};

const ResourcePreview = ({
  resourceId,
  load,
  resource,
  activeId,
  showList,
  partnershipName,
  partnershipActiveId,
  getSignedUrl,
  imageUrl,
  setActiveImage,
  imagePath,
  ...props
}) => {
  // load record data from API
  useEffect(() => {
    if (resourceId) {
      load(activeId, resourceId);
    }
  }, [activeId, resourceId, load]);

  useFetchSignedUrl(imagePath, imageUrl, getSignedUrl, setActiveImage);

  const onClickCancel = () => {
    props.hideDetail();
  };

  const onClickLinks = (event, item, type) => {
    event.stopPropagation();

    MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.OPEN_LINK, {
      partnershipName: partnershipName,
      partnershipId: partnershipActiveId,
      resourceId: item.id,
      resourceName: item.name,
      linkType: types[type],
    });
  };

  if (resource === null) {
    return null;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            zIndex={3}
            title={
              <Title level={3} className='resource-preview-title'>
                {t('resources_preview')}
              </Title>
            }
            centered
            className='resource-preview'
            visible={props.visible}
            onCancel={onClickCancel}
            footer={null}
            width={900}
            bodyStyle={{ paddingTop: 0 }}
            forceRender={true}
          >
            <div
              className='resource-card'
              style={{
                border: resource?.partnership
                  ? `2px solid ${resource?.partnership.color_hex}`
                  : resource.is_universal
                  ? '2px solid #1A2E5F'
                  : null,
              }}
            >
              <Row align='top' justify='space-between'>
                <Col
                  xs={{ span: resource?.logo ? 18 : 24, order: 1 }}
                  md={{ span: resource?.logo ? 18 : 24, order: 1 }}
                  lg={{ span: resource?.logo ? 18 : 24, order: 1 }}
                  xl={{ span: resource?.logo ? 18 : 24, order: 1 }}
                >
                  <Title className={'resource-card-title'} level={3}>
                    {resource.name}
                  </Title>
                </Col>
                <Col
                  xs={{ span: 6, order: 2 }}
                  md={{ span: 6, order: 2 }}
                  lg={{ span: 6, order: 2 }}
                  xl={{ span: 6, order: 2 }}
                >
                  <div className={'resources-card-image'}>
                    {imageUrl && (
                      <figure>
                        <img
                          style={{ maxWidth: '100%' }}
                          src={imageUrl}
                          alt=''
                        />
                      </figure>
                    )}
                  </div>
                </Col>
              </Row>

              <Row align='middle'>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                >
                  {!resource.is_universal ? (
                    resource?.partnership ? (
                      <Tag
                        color={resource.partnership?.color_hex}
                        className='cursor-pointer'
                      >
                        {resource.partnership?.name}
                      </Tag>
                    ) : (
                      <Tag color='#FE971F' className='cursor-pointer'>
                        {t('resource_notes_tag_custom_resource')}
                      </Tag>
                    )
                  ) : (
                    <Tag color={'#1A2E5F'} className='cursor-pointer'>
                      {t('resource_notes_tag_general_resource')}
                    </Tag>
                  )}
                </Col>
              </Row>

              <Divider style={{ margin: '12px 0' }} />

              {resource.service_tags && resource.service_tags.length > 0 ? (
                <div className='resource-list-tag-card'>
                  {resource.service_tags.map((x, i) => (
                    <Tag color={x.color_hex} key={i}>
                      {x.label}
                    </Tag>
                  ))}
                </div>
              ) : null}
              {resource.description !== '<p><br></p>' &&
                resource.description.length > 0 && (
                  <div className='resource-list-description-preview'>
                    <div id='toolbar-read-only-resource'></div>
                    <ReactQuill
                      modules={modules}
                      readOnly
                      value={resource.description}
                    />
                  </div>
                )}
              <div className='info-container'>
                {resource?.website ? (
                  <WebsiteDisplayAdmin
                    website={resource.website}
                    onClick={(e) => onClickLinks(e, resource, 'link')}
                  />
                ) : null}
                {resource?.telephone ? (
                  <TelephoneDisplayAdmin
                    telephone={resource.telephone}
                    onClick={(e) => onClickLinks(e, resource, 'phone')}
                  />
                ) : null}
                {resource?.email ? (
                  <EmailDisplayAdmin
                    email={resource.email}
                    onClick={(e) => onClickLinks(e, resource, 'email')}
                  />
                ) : null}
              </div>
              <ResourcePreviewFotter resource={resource} showList={showList} />
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default ResourcePreview;

Logger.log('silly', `ResourcePreview loaded.`);
