import axios from 'axios';
import { Map } from 'immutable';

import { sessionPasswordExpired } from './actions';
import store from './store';
import Auth from '../lib/Auth';
import QueryString from '../lib/QueryString';
import QueryStringStrapi from '../lib/QueryStringStrapi';
import Logger from '../lib/Logger';
import Config from '../Config';

class API {
  constructor(config, axios) {
    Logger.log('debug', `API.constructor(###, ###)`);

    // config props
    this.environment = config.get('ENVIRONMENT');
    this.base_url = config.get('API_BASE_URL');
    this.base_url_strapi = config.get('API_BASE_URL_STRAPI') + '/api';
    this.strapiToken = config.get('STAPI_TOKEN');
    this.app_key = config.get('API_APP_KEY');
    this.x_api_key = config.get('X_API_KEY');

    // internal props
    this.axios = axios;
    this.authToken = null;
    this.authExpires = null;
    this.destroySession = null;
  }

  setAuthToken(authToken) {
    this.authToken = authToken;
  }

  setAuthExpires(authExpires) {
    this.authExpires = authExpires;
  }

  setDestroySession(destroySession) {
    this.destroySession = destroySession;
  }

  // destroy session data and redirect to auth screen
  _logout = () => {
    Logger.log('debug', `API._logout()`);
    this.destroySession();
  };

  // get authentication token
  _getAuthToken() {
    Logger.log('debug', `API._getAuthToken()`);
    const authToken = this.authToken;
    const authExpires = this.authExpires;
    return Auth.isAuthTokenValid(authToken, authExpires) ? authToken : false;
  }

  // get request headers
  _getRequestHeaders(
    validateStatus = null,
    authenticate = true,
    dynamicRequestHeaders = {}
  ) {
    Logger.log(
      'debug',
      `API._getRequestHeaders(${validateStatus}, ${authenticate})`
    );

    const authToken = this._getAuthToken();
    if (authenticate && !authToken) {
      this._logout();
      return false;
    }
    const authConfig = authToken
      ? {
          headers: {
            Authorization: `Bearer ${authToken}`,
            ...dynamicRequestHeaders,
          },
        }
      : { headers: { ...dynamicRequestHeaders } };
    const vaildateStatusConfig = validateStatus
      ? { validateStatus: (status) => status < validateStatus }
      : {};

    return { ...authConfig, ...vaildateStatusConfig };
  }

  // get request headers
  _getRequestHeadersStrapi(validateStatus = null, authenticate = true) {
    Logger.log(
      'debug',
      `API._getRequestHeadersStrapi(${validateStatus}, ${authenticate})`
    );

    if (authenticate && !this.strapiToken) {
      this._logout();
      return false;
    }
    const authConfig = this.strapiToken
      ? { headers: { Authorization: `Bearer ${this.strapiToken}` } }
      : {};
    const vaildateStatusConfig = validateStatus
      ? { validateStatus: (status) => status < validateStatus }
      : {};
    return { ...authConfig, ...vaildateStatusConfig };
  }

  // get full URI for an API endpoint
  _getUri(path) {
    Logger.log('debug', `API._getUri(${path})`);
    return (
      this.base_url +
      path +
      (path.includes('?') ? '&' : '?') +
      'app_key=' +
      this.app_key
    );
  }

  // get full URI for an API endpoint
  _getUriStrapi(path, plan_name = null, page = null, limit = null) {
    Logger.log('debug', `API._getUriStrapi(${path})`);

    let url = this.base_url_strapi + path + (path.includes('?') ? '&' : '?');

    return url;
  }

  // generic API call helper
  async _callApi(
    method,
    path,
    payload = null,
    authenticate = true,
    dynamicRequestHeaders = {},
    dynamicRequestAttributes = {}
  ) {
    Logger.log(
      'debug',
      `API._callApi(###, ${path}, ###, ${authenticate})`,
      payload
    );

    // get headers and check authentication token
    const requestHeaders = this._getRequestHeaders(
      500,
      authenticate,
      dynamicRequestHeaders
    );
    if (false === requestHeaders) {
      return Map({ status: 401, data: { error: `Not Authenticated` } });
    }
    const self = this;
    if (payload) {
      // call API with payload
      return await method(path, payload, requestHeaders)
        .then(async function (response) {
          Logger.log('verbose', `API Response: %j`, response);
          if (response.status === 401) {
            self._logout();
          } else if (response.status === 403 && response.data.update_password) {
            store.dispatch(sessionPasswordExpired());
          }
          return Map(response);
        })
        .catch(function (error) {
          Logger.log('error', `${error}`);
          return Map({ status: 500, data: { error: `${error}` } });
        });
    } else {
      // call API without payload
      return await method(path, {
        ...requestHeaders,
        ...dynamicRequestAttributes,
      })
        .then(async function (response) {
          Logger.log('verbose', `API Response: %j`, response);
          if (response.status === 401) {
            self._logout();
          } else if (response.status === 403 && response.data.update_password) {
            store.dispatch(sessionPasswordExpired());
          }
          return Map(response);
        })
        .catch(function (error) {
          Logger.log('error', `${error}`);
          return Map({ status: 500, data: { error: `${error}` } });
        });
    }
  }

  // generic API call helper
  async _callApiStrapi(method, path, payload = null, authenticate = true) {
    Logger.log(
      'debug',
      `API._callApiStrapi(###, ${path}, ###, ${authenticate})`,
      payload
    );

    // get headers and check authentication token
    const requestHeaders = this._getRequestHeadersStrapi(500, authenticate);
    if (false === requestHeaders) {
      return Map({ status: 401, data: { error: `Not Authenticated` } });
    }

    const self = this;
    if (payload) {
      // call API with payload
      return await method(path, payload, requestHeaders)
        .then(async function (response) {
          Logger.log('verbose', `API Response: %j`, response);
          if (response.status === 401) {
            self._logout();
          } else if (response.status === 403 && response.data.update_password) {
            store.dispatch(sessionPasswordExpired());
          }
          return Map(response);
        })
        .catch(function (error) {
          Logger.log('error', `${error}`);
          return Map({ status: 500, data: { error: `${error}` } });
        });
    } else {
      // call API without payload
      return await method(path, requestHeaders)
        .then(async function (response) {
          Logger.log('verbose', `API Response: %j`, response);
          if (response.status === 401) {
            self._logout();
          } else if (response.status === 403 && response.data.update_password) {
            store.dispatch(sessionPasswordExpired());
          }
          return Map(response);
        })
        .catch(function (error) {
          Logger.log('error', `${error}`);
          return Map({ status: 500, data: { error: `${error}` } });
        });
    }
  }

  // GET method
  async GET(
    path,
    authenticate = true,
    dynamicRequestHeaders = {},
    dynamicRequestAttributes = {}
  ) {
    Logger.log('debug', `API.GET(${path}, ${authenticate})`);
    return await this._callApi(
      this.axios.get,
      path,
      null,
      authenticate,
      dynamicRequestHeaders,
      dynamicRequestAttributes
    );
  }

  // POST method
  async POST(path, payload, authenticate = true) {
    Logger.log('debug', `API.POST(${path}, ###, ${authenticate})`, payload);
    return await this._callApi(
      this.axios.post,
      path,
      payload,
      authenticate,
      {}
    );
  }

  // PUT method
  async PUT(path, payload, authenticate = true) {
    Logger.log('debug', `API.PUT(${path}, ###, ${authenticate})`, payload);
    return await this._callApi(this.axios.put, path, payload, authenticate, {});
  }

  // PATCH method
  async PATCH(path, payload, authenticate = true) {
    Logger.log('debug', `API.PATCH(${path}, ###, ${authenticate}`, payload);
    return await this._callApi(
      this.axios.patch,
      path,
      payload,
      authenticate,
      {}
    );
  }

  // DELETE method
  async DELETE(path, authenticate = true) {
    Logger.log('debug', `API.DELETE(${path}, ${authenticate})`);
    return await this._callApi(this.axios.delete, path, null, authenticate, {});
  }

  // GETStrapi method
  async GETStrapi(path, authenticate = true) {
    Logger.log('debug', `API.GETStrapi(${path}, ${authenticate})`);
    return await this._callApiStrapi(this.axios.get, path, null, authenticate);
  }

  // GET /token
  async getToken(username, password, token = null, otp = null) {
    Logger.log('debug', `API.getToken(###, ###, ###, ###)`);

    // prep credentials header config
    const authConfig = {
      validateStatus: function (status) {
        return status < 500;
      },
    };

    // use basic auth or bearer token (with optional one-time password)
    if (token === null) {
      authConfig['auth'] = {
        username: username,
        password: password,
      };
    } else {
      authConfig['headers'] = {};
      if (otp !== null) {
        authConfig['headers']['Authorization'] = `TOTP ${token} ${otp}`;
      } else {
        authConfig['headers']['Authorization'] = `Bearer ${token}`;
      }
    }

    // call API
    const output = await this.axios
      .get(this._getUri(`/token`), authConfig)
      .then(async function (response) {
        Logger.log('verbose', `API Response:`, response);
        return Map(response);
      })
      .catch(function (error) {
        Logger.log('error', `${error}`);
        return Map({ status: 500, data: { error: `${error}` } });
      });

    return output;
  }

  // POST /register/step1
  async postRegisterStep1(payload) {
    Logger.log('debug', `API.postRegisterStep1(###)`, payload);
    return await this.POST(this._getUri(`/register/step1`), payload, false);
  }

  // POST /register/step2
  async postRegisterStep2(payload) {
    Logger.log('debug', `API.postRegisterStep2(###)`, payload);
    return await this.POST(this._getUri(`/register/step2`), payload, false);
  }

  // POST /register/create_account
  async postRegisterCreateAccount(payload) {
    Logger.log('debug', `API.postRegisterCreateAccount(###)`, payload);
    return await this.POST(
      this._getUri(`/register/create_account`),
      payload,
      false
    );
  }

  // POST /register/create_account_owner
  async postRegisterCreateAccountOwner(payload) {
    Logger.log('debug', `API.postRegisterCreateAccountOwner(###)`, payload);
    return await this.POST(
      this._getUri(`/register/create_account_owner`),
      payload,
      false
    );
  }

  // POST /register/create_account_admin
  async postRegisterCreateAccountAdmin(payload) {
    Logger.log('debug', `API.postRegisterCreateAccountAdmin(###)`, payload);
    return await this.POST(
      this._getUri(`/register/create_account_admin`),
      payload,
      false
    );
  }

  // POST /register/create_account_client
  async postRegisterCreateAccountClient(payload) {
    Logger.log('debug', `API.postRegisterCreateAccountClient(###)`, payload);
    return await this.POST(
      this._getUri(`/register/create_account_client`),
      payload,
      false
    );
  }

  // GET /user_account
  async getUserAccount() {
    Logger.log('debug', `API.getUserAccount()`);
    return await this.GET(this._getUri(`/user_account`));
  }

  // PUT /user_account
  async putUserAccount(payload) {
    Logger.log('debug', `API.putUserAccount(###)`, payload);
    return await this.PUT(this._getUri(`/user_account`), payload);
  }

  // POST /user_account/avatar
  async postUserAccountAvatar(payload) {
    Logger.log('debug', `API.postUserAccountAvatar(###)`);
    return await this.POST(this._getUri(`/user_account/avatar`), payload);
  }

  // DELETE /user_account/avatar
  async deleteUserAccountAvatar() {
    Logger.log('debug', `API.deleteUserAccountAvatar()`);
    return await this.DELETE(this._getUri(`/user_account/avatar`));
  }

  // PUT /user_account/alerts
  async putUserAccountAlerts(payload) {
    Logger.log('debug', `API.putUserAccountAlerts(###)`, payload);
    return await this.PUT(this._getUri(`/user_account/alerts`), payload);
  }

  // POST /password/request-reset-code
  async postPasswordRequestResetCode(payload) {
    Logger.log('debug', `API.postPasswordRequestResetCode(###)`);
    return await this.POST(
      this._getUri(`/password/request-reset-code`),
      payload,
      false
    );
  }

  // PUT /password/reset
  async putPasswordReset(payload) {
    Logger.log('debug', `API.putPasswordReset(###)`);
    return await this.PUT(this._getUri(`/password/reset`), payload, false);
  }

  // PUT /user_account/password
  async putUserAccountPassword(payload) {
    Logger.log('debug', `API.putUserAccountPassword(###)`);
    return await this.PUT(this._getUri(`/user_account/password`), payload);
  }

  // PUT /user_account/2fa
  async putAccount2FA(payload) {
    Logger.log('debug', `API.putAccount2FA(%j)`, payload);
    return await this.PUT(this._getUri(`/user_account/2fa`), payload);
  }

  // POST /user_account/email-confirmation
  async postUserAccountEmailConfirmation(payload) {
    Logger.log('debug', `API.postUserAccountEmailConfirmation(###)`);
    return await this.POST(
      this._getUri(`/user_account/email-confirmation`),
      payload
    );
  }

  // GET /timezones
  async getTimezones() {
    Logger.log('debug', `API.getTimezones()`);
    return await this.GET(this._getUri(QueryString.append(`/timezones`)));
  }

  // GET /terms_of_service/current
  async getTermsOfServiceCurrent() {
    Logger.log('debug', `API.getTermsOfServiceCurrent()`);
    return await this.GET(this._getUri(`/terms_of_service/current`), false);
  }

  // GET /relationships
  async getRelationships(page = 1, limit = 50, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getRelationships(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/relationships/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      ),
      false
    );
  }

  // GET /account/{id}
  async getAccount(id) {
    Logger.log('debug', `API.getAccount(${id})`);
    return await this.GET(this._getUri(`/account/${id}`));
  }

  // PUT /account/{id}/plan
  async putAccountPlan(id, payload) {
    Logger.log('debug', `API.putAccountPlan(${id}, ###)`);
    return await this.PUT(this._getUri(`/account/${id}/plan`), payload);
  }

  // GET /care-guides
  async getCareGuides(
    page = 1,
    limit = 50,
    order = null,
    filter = null,
    partnershipId = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuides(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guides/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, partnershipId: partnershipId, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{id}
  async getCareGuide(id) {
    Logger.log('debug', `API.getCareGuide(${id})`);
    return await this.GET(this._getUri(`/care-guide/${id}`));
  }

  // PUT /care-guide/{id}
  async putCareGuide(id, payload) {
    Logger.log('debug', `API.putCareGuide(${id}, ###)`);
    return await this.PUT(this._getUri(`/care-guide/${id}`), payload);
  }

  // PATCH /care-guide/{id}
  async patchCareGuide(id, payload) {
    Logger.log('debug', `API.patchCareGuide(${id}, %j)`, payload);
    return await this.PATCH(this._getUri(`/care-guide/${id}`), payload);
  }

  // GET /care-guide/{careGuideId}/pdf
  async getCareGuidePDF(careGuideId) {
    Logger.log('debug', `API.getCareGuidePDF(${careGuideId})`);
    return await this.GET(
      this._getUri(QueryString.append(`/care-guide/${careGuideId}/pdf`))
    );
  }

  // GET /care-guides
  async getTotalMembers(partnershipId = null, filter = null) {
    Logger.log('debug', `API.getTotalMembers(%j)`, filter);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guides-partnership/${partnershipId}/count`, {
          ...filter,
        })
      )
    );
  }

  // GET /care-guide/{careGuideId}/segment/<segmentId>/pdf
  async getCareGuideSegmentPDF(careGuideId, segmentId) {
    Logger.log(
      'debug',
      `API.getCareGuideSegmentPDF(${careGuideId}, ${segmentId})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/segment/${segmentId}/pdf`
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/segments
  async postCareGuideSegments(careGuideId, payload) {
    Logger.log('debug', `API.postCareGuideSegments(${careGuideId}, ###)`);
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/segments`),
      payload
    );
  }

  // PUT /care-guide/{careGuideId}/segment/{segmentId}
  async putCareGuideSegment(careGuideId, segmentId, payload) {
    Logger.log(
      'debug',
      `API.putCareGuideSegment(${careGuideId}, ${segmentId}, ###)`
    );
    return await this.PUT(
      this._getUri(`/care-guide/${careGuideId}/segment/${segmentId}`),
      payload
    );
  }

  // DELETE /care-guide/{care_guide_id}/segment/{segmentId}
  async deleteCareGuideSegment(careGuideId, segmentId) {
    Logger.log(
      'debug',
      `API.deleteCareGuideSegment(${careGuideId}, ${segmentId})`
    );
    return await this.DELETE(
      this._getUri(`/care-guide/${careGuideId}/segment/${segmentId}`)
    );
  }

  // POST /care-guide/{care_guide_id}/image
  async postCareGuideImage(careGuideId, payload) {
    Logger.log('debug', `API.postCareGuideImage(###)`);
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/image`),
      payload
    );
  }

  // DELETE /care-guide/{care_guide_id}/image
  async deleteCareGuideImage(careGuideId) {
    Logger.log('debug', `API.deleteCareGuideImage()`);
    return await this.DELETE(this._getUri(`/care-guide/${careGuideId}/image`));
  }

  // GET /care-guide/{careGuideId}/helpers
  async getCareGuideHelpers(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideHelpers(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/helpers/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // PUT /care-guide/{care_guide_id}/helper/{id}
  async putCareGuideHelper(careGuideId, id, payload) {
    Logger.log(
      'debug',
      `API.putCareGuideHelper(${careGuideId}, ${id}, %j)`,
      payload
    );
    return await this.PUT(
      this._getUri(`/care-guide/${careGuideId}/helper/${id}`),
      payload
    );
  }

  // PATCH /care-guide/{care_guide_id}/helper/{id}
  async patchCareGuideHelper(careGuideId, id, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideHelper(${careGuideId}, ${id}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(`/care-guide/${careGuideId}/helper/${id}`),
      payload
    );
  }

  // DELETE /care-guide/{care_guide_id}/helper/{id}
  async deleteCareGuideHelper(careGuideId, id) {
    Logger.log('debug', `API.deleteCareGuideHelper(${careGuideId}, ${id})`);
    return await this.DELETE(
      this._getUri(`/care-guide/${careGuideId}/helper/${id}`)
    );
  }

  // GET /care-guide-helper-tags
  async getCareGuideHelperTags(
    page = 1,
    limit = 500,
    order = 'label.asc',
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideHelperTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-helper-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/members
  async getCareGuideMembers(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideMembers(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/members/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/invitations
  async getCareGuideInvitations(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideInvitations(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/invitations/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/invitations
  async postCareGuideInvitations(careGuideId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideInvitations(${careGuideId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/invitations`),
      payload
    );
  }

  // PATCH /care-guide/{care_guide_id}/invitation/{id}
  async patchCareGuideInvitation(careGuideId, id, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideInvitation(${careGuideId}, ${id}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(`/care-guide/${careGuideId}/invitation/${id}`),
      payload
    );
  }

  // DELETE /care-guide/{care_guide_id}/invitation/{id}
  async deleteCareGuideInvitation(careGuideId, id) {
    Logger.log('debug', `API.deleteCareGuideInvitation(${careGuideId}, ${id})`);
    return await this.DELETE(
      this._getUri(`/care-guide/${careGuideId}/invitation/${id}`)
    );
  }

  // PUT /invitation/{id}/response
  async putInvitationResponse(id, payload) {
    Logger.log('debug', `API.putInvitationResponse(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/invitation/${id}/response`), payload);
  }

  // GET /invitations
  async getInvitations(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getInvitations(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/invitations/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/journal/posts
  async getCareGuideJournalPosts(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideJournalPosts(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/journal/posts/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/journal/post/{journalPostId}
  async getCareGuideJournalPost(careGuideId, journalPostId) {
    Logger.log(
      'debug',
      `API.getCareGuideJournalPost(${careGuideId}, ${journalPostId})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/journal/post/${journalPostId}`
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/journal/posts
  async postCareGuideJournalPosts(careGuideId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideJournalPosts(${careGuideId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/journal/posts`),
      payload
    );
  }

  // PATCH /care-guide/{careGuideId}/journal/post/{journalPostId}
  async patchCareGuideJournalPost(careGuideId, journalPostId, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideJournalPost(${careGuideId}, ${journalPostId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(`/care-guide/${careGuideId}/journal/post/${journalPostId}`),
      payload
    );
  }

  // GET /care-guide/{careGuideId}/journal/posts/pdf
  async getCareGuideJournalPostsPDF(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideJournalPostsPDF(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/journal/posts/pdf/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/journal/post/{journalPostId}/replies
  async getCareGuideJournalPostReplies(
    careGuideId,
    journalPostId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideJournalPostReplies(${careGuideId}, ${journalPostId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/journal/post/${journalPostId}/replies/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/journal/post/{journalPostId}/reply/{journalReplyId}
  async getCareGuideJournalPostReply(
    careGuideId,
    journalPostId,
    journalReplyId
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideJournalPostReply(${careGuideId}, ${journalPostId}, ${journalReplyId})`
    );
    return await this.GET(
      this._getUri(
        `/care-guide/${careGuideId}/journal/post/${journalPostId}/reply/${journalReplyId}`
      )
    );
  }

  // POST /care-guide/{careGuideId}/journal/post/{journalPostId}/replies
  async postCareGuideJournalPostReplies(careGuideId, journalPostId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideJournalPostReplies(${careGuideId}, ${journalPostId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(
        `/care-guide/${careGuideId}/journal/post/${journalPostId}/replies`
      ),
      payload
    );
  }

  // PATCH /care-guide/{careGuideId}/journal/post/{journalPostId}/reply/{journalReplyId}
  async patchCareGuideJournalPostReply(
    careGuideId,
    journalPostId,
    journalReplyId,
    payload
  ) {
    Logger.log(
      'debug',
      `API.patchCareGuideJournalPostReply(${careGuideId}, ${journalPostId}, ${journalReplyId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(
        `/care-guide/${careGuideId}/journal/post/${journalPostId}/reply/${journalReplyId}`
      ),
      payload
    );
  }

  // GET /journal-tags
  async getJournalTags(
    page = 1,
    limit = 500,
    order = 'label.asc',
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getJournalTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/journal-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /messages/inbox
  async getMessagesInbox(page = 1, limit = 50, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getMessagesInbox(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/messages/inbox/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /messages/drafts
  async getMessagesDrafts(page = 1, limit = 50, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getMessagesDrafts(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/messages/drafts/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /messages/sent
  async getMessagesSent(page = 1, limit = 50, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getMessagesSent(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/messages/sent/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /messages/archived
  async getMessagesArchived(page = 1, limit = 50, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getMessagesArchived(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/messages/archived/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /messages/deleted
  async getMessagesDeleted(page = 1, limit = 50, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getMessagesDeleted(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/messages/deleted/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // POST /messages
  async postMessages(payload) {
    Logger.log('debug', `API.postMessages(%j)`, payload);
    return await this.POST(this._getUri(`/messages`), payload);
  }

  // PUT /message/{message_id}
  async putMessage(messageId, payload) {
    Logger.log('debug', `API.putMessage(${messageId}, %j)`, payload);
    return await this.PUT(this._getUri(`/message/${messageId}`), payload);
  }

  // PATCH /message/{message_id}
  async patchMessage(messageId, payload) {
    Logger.log('debug', `API.patchMessage(${messageId}, %j)`, payload);
    return await this.PATCH(this._getUri(`/message/${messageId}`), payload);
  }

  // PATCH /message/{message_id}/recipient/{id}
  async patchMessageRecipient(messageId, id, payload) {
    Logger.log(
      'debug',
      `API.patchMessageRecipient(${messageId}, ${id}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(`/message/${messageId}/recipient/${id}`),
      payload
    );
  }

  // DELETE /message/{message_id}
  async deleteMessage(messageId, payload) {
    Logger.log('debug', `API.deleteMessage(${messageId}, %j)`, payload);
    return await this.DELETE(this._getUri(`/message/${messageId}`), payload);
  }

  // GET /messages/counts
  async getMessagesCounts() {
    Logger.log('debug', `API.getMessagesCounts()`);
    return await this.GET(this._getUri(`/messages/counts`));
  }

  // POST /care-guides-members-partnership/{partnership_id}
  async postCareguidesMembersPartnership(
    partnershipId,
    page,
    limit,
    order,
    filter,
    payload
  ) {
    Logger.log(
      'debug',
      `API.postCareguidesMembersPartnership(${partnershipId}, ${page}, ${limit}, ${order}, %j, %j)`,
      filter,
      payload
    );
    return await this.POST(
      this._getUri(
        QueryString.append(
          `/care-guides-members-partnership/${partnershipId}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      ),
      payload
    );
  }

  async postCareguidesPartnershipCalendarEvent(partnershipId, payload) {
    Logger.log(
      'debug',
      `API.getCareguidesEventParticipants(${partnershipId})`,
      payload
    );
    return await this.POST(
      this._getUri(`/care-guides/partnership/${partnershipId}/calendar-events`),
      payload
    );
  }

  // GET /care-guide/{careGuideId}/calendar/events
  async getCareGuideCalendarEvents(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideCalendarEvents(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/calendar/events/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/calendar/events
  async postCareGuideCalendarEvents(careGuideId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideCalendarEvents(${careGuideId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/calendar/events`),
      payload
    );
  }

  // POST /care-guide/event/{event_id}/calendar/events/partnership/{partnership_id}
  async postCareGuideCalendarEventsPartner(eventId, partnershipId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideCalendarEventsPartner(${eventId}, ${partnershipId} %j)`,
      payload
    );
    return await this.POST(
      this._getUri(
        `/care-guide/event/${eventId}/calendar/events/partnership/${partnershipId}`
      ),
      payload
    );
  }

  // PUT /care-guide/{careGuideId}/calendar/event/{eventId}
  async putCareGuideCalendarEvent(careGuideId, eventId, payload) {
    Logger.log(
      'debug',
      `API.putCareGuideCalendarEvent(${careGuideId}, ${eventId}, ###)`
    );
    return await this.PUT(
      this._getUri(`/care-guide/${careGuideId}/calendar/event/${eventId}`),
      payload
    );
  }

  // PUT /care-guide/care-guide/partnership/{partnershipId}/calendar/event/{eventId}
  async putCareGuideCalendarEventPartner(partnershipId, eventId, payload) {
    Logger.log(
      'debug',
      `API.putCareGuideCalendarEvent(${partnershipId}, ${eventId}, ###)`
    );
    return await this.PUT(
      this._getUri(
        `/care-guide/partnership/${partnershipId}/calendar/event/${eventId}`
      ),
      payload
    );
  }

  // PATCH /care-guide/{careGuideId}/calendar/event/{eventId}
  async patchCareGuideCalendarEvent(careGuideId, eventId, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideCalendarEvent(${careGuideId}, ${eventId}, ###)`
    );
    return await this.PATCH(
      this._getUri(`/care-guide/${careGuideId}/calendar/event/${eventId}`),
      payload
    );
  }

  // PATCH /care-guide/{careGuideId}/calendar/event/{eventId}
  async patchCareGuideCalendarEventPartner(eventId, partnershipId, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideCalendarEventPartner(${eventId}, ${partnershipId}, ###)`
    );
    return await this.PATCH(
      this._getUri(
        `/care-guide/partnership/${partnershipId}/calendar/event/${eventId}`
      ),
      payload
    );
  }

  // DELETE /care-guide/{careGuideId}/calendar/event/{eventId}
  async deleteCareGuideCalendarEvent(careGuideId, eventId, payload) {
    Logger.log(
      'debug',
      `API.deleteCareGuideCalendarEvent(${careGuideId}, ${eventId}, ###)`
    );
    return await this.DELETE(
      this._getUri(`/care-guide/${careGuideId}/calendar/event/${eventId}`),
      payload
    );
  }

  // DELETE /care-guide/{partnershipId}/calendar/event/{eventId}
  async deleteCalendarPartnerEvent(partnershipId, eventId, payload) {
    Logger.log(
      'debug',
      `API.deleteCalendarPartnerEvent(${partnershipId}, ${eventId}, ###)`
    );
    return await this.DELETE(
      this._getUri(
        `/care-guide/partnership/${partnershipId}/calendar/event/${eventId}`
      ),
      payload
    );
  }

  // GET /care-guide/{careGuideId}/calendar/participant/events
  async getCareGuideCalendarParticipantEvents(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideCalendarParticipantEvents(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/calendar/participant/events/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // PUT /calendar/event/participant/{participantId}/response
  async putCalendarEventParticipantResponse(participantId, payload) {
    Logger.log(
      'debug',
      `API.putCalendarEventParticipantResponse(${participantId}, ###)`
    );
    return await this.PUT(
      this._getUri(`/calendar/event/participant/${participantId}/response`),
      payload
    );
  }

  // GET /calendar-events-admin-participants/${calendarEventId}
  async getCalendarEventParticipants(calendarEventId, page = 1, limit = 10) {
    Logger.log(
      'debug',
      `API.getCalendarEventParticipants(${calendarEventId}, ${page})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/calendar-events-admin-participants/${calendarEventId}/${parseInt(
            page
          )}/${parseInt(limit)}`
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/calendar/events
  async postCalendarEventParticipant(eventId, payload) {
    Logger.log(
      'debug',
      `API.postCalendarEventParticipant(${eventId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/calendar-events-admin/${eventId}/participant`),
      payload
    );
  }

  // PUT /calendar-events-admin-participant/{participantId}
  async putCalendarEventAdminParticipant(participantId, payload) {
    Logger.log(
      'debug',
      `API.putCalendarEventAdminParticipant(${participantId}, ###)`
    );
    return await this.PUT(
      this._getUri(`/calendar-events-admin-participant/${participantId}`),
      payload
    );
  }

  // DELETE /calendar-events-admin-participant/{CalendarEventParticipantId}
  async deleteCalendarEventAdminParticipant(
    calendarEventParticipantId,
    payload
  ) {
    Logger.log(
      'debug',
      `API.deleteCalendarEventAdminParticipant(${calendarEventParticipantId})`
    );
    return await this.DELETE(
      this._getUri(
        `/calendar-events-admin-participant/${calendarEventParticipantId}`
      ),
      payload
    );
  }

  // POST /care-guide/{careGuideId}/calendar/event/{eventId}/exceptions
  async postCareGuideCalendarEventExceptions(careGuideId, eventId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideCalendarEventExceptions(${careGuideId}, ${eventId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(
        `/care-guide/${careGuideId}/calendar/event/${eventId}/exceptions`
      ),
      payload
    );
  }

  // GET /care-guide/{careGuideId}/resources
  async getCareGuideResources(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideResources(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/resources/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/partnership/{partnershipId}/resources-dashboard
  async getCareGuideResourcesDashboard(
    careGuideId = null,
    partnershipId = null,
    page = 1,
    limit = 50,
    order = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideResources(${careGuideId}, ${partnershipId}, ${page}, ${limit}, ${order})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/partnership/${partnershipId}/resources-dashboard/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/resources
  async postCareGuideResources(careGuideId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideResources(${careGuideId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/resources`),
      payload
    );
  }

  // GET /care-guide/{careGuideId}/resource/{resourceId}
  async getCareGuideResource(careGuideId, id) {
    Logger.log('debug', `API.getCareGuideResource(${careGuideId}, ${id})`);
    return await this.GET(
      this._getUri(`/care-guide/${careGuideId}/resource/${id}`)
    );
  }

  // PUT /care-guide/{careGuideId}/resource/{resourceId}
  async putCareGuideResource(careGuideId, resourceId, payload) {
    Logger.log(
      'debug',
      `API.putCareGuideResource(${careGuideId}, ${resourceId}, ###)`
    );
    return await this.PUT(
      this._getUri(`/care-guide/${careGuideId}/resource/${resourceId}`),
      payload
    );
  }

  // PATCH /care-guide/{careGuideId}/journal/post/{resourceId}
  async patchCareGuideResource(careGuideId, resourceId, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideResource(${careGuideId}, ${resourceId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(`/care-guide/${careGuideId}/resource/${resourceId}`),
      payload
    );
  }

  // PATCH /care-guide/{careGuideId}/resource/{resourceId}/interaction
  async patchResourceFavorite(careGuideId, resourceId, payload) {
    Logger.log(
      'debug',
      `API.patchResourceFavorite(${careGuideId}, ${resourceId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(
        `/care-guide/${careGuideId}/resource/${resourceId}/favorite`
      ),
      payload
    );
  }

  // DELETE /care-guide/{careGuideId}/resource/{resourceId}
  async deleteCareGuideResource(careGuideId, resourceId, payload) {
    Logger.log(
      'debug',
      `API.deleteCareGuideResource(${careGuideId}, ${resourceId}, ###)`
    );
    return await this.DELETE(
      this._getUri(`/care-guide/${careGuideId}/resource/${resourceId}`),
      payload
    );
  }

  // GET
  async getResourcesCsv(partnershipId = null, resourceId = null) {
    Logger.log('debug', `API.getResourceNotesCSV()`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/admin/partnership/${partnershipId}/resource/${resourceId}/csv`
        )
      ),
      true,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  // GET
  async getResourcesPdf(partnershipId = null, resourceId = null) {
    Logger.log('debug', `API.getResourceNotesPDF()`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/admin/partnership/${partnershipId}/resource/${resourceId}/pdf`
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/resource/{resourceId}/interaction
  async postResourceInteraction(careGuideId, resourceId, payload) {
    Logger.log(
      'debug',
      `API.postResourceInteraction(${careGuideId}, ${resourceId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(
        `/care-guide/${careGuideId}/resource/${resourceId}/interaction`
      ),
      payload
    );
  }

  // GET /care-guide/{careGuideId}/resources/pdf
  async getCareGuideResourcesPDF(
    careGuideId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideResourcesPDF(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/resources/pdf/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /resource-tags
  async getResourceTags(
    page = 1,
    limit = 500,
    order = 'label.asc',
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getResourceTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resource-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /resource-service-tags
  async getResourceServiceTags(
    page = 1,
    limit = 500,
    order = 'label.asc',
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getResourceServiceTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resource-service-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/resource/{resourceId}/notes
  async getCareGuideResourceNotes(
    careGuideId,
    resourceId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideResourceNotes(${careGuideId}, ${resourceId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/resource/${resourceId}/notes/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/resource/{resourceId}/notes
  async getCareGuideResourceNotesAdmin(
    partnership,
    resourceId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideResourceNotesAdmin(${partnership}, ${resourceId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/admin/partnership/${partnership}/resource/${resourceId}/notes/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/resource/{resourceId}/note/{noteId}
  async getCareGuideResourceNote(careGuideId, resourceId, noteId) {
    Logger.log(
      'debug',
      `API.getCareGuideResourceNote(${careGuideId}, ${resourceId}, ${noteId})`
    );
    return await this.GET(
      this._getUri(
        `/care-guide/${careGuideId}/resource/${resourceId}/note/${noteId}`
      )
    );
  }

  // POST /care-guide/{careGuideId}/resource/{resourceId}/notes
  async postCareGuideResourceNotes(careGuideId, resourceId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideResourceNotes(${careGuideId}, ${resourceId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/resource/${resourceId}/notes`),
      payload
    );
  }

  // PATCH /care-guide/{careGuideId}/resource/{resourceId}/note/{noteId}
  async patchCareGuideResourceNote(careGuideId, resourceId, noteId, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideResourceNote(${careGuideId}, ${resourceId}, ${noteId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(
        `/care-guide/${careGuideId}/resource/${resourceId}/note/${noteId}`
      ),
      payload
    );
  }

  // DELETE /care-guide/{careGuideId}/resource/{resourceId}/note/{noteId}
  async deleteCareGuideResourceNote(careGuideId, resourceId, noteId) {
    Logger.log(
      'debug',
      `API.patchCareGuideResourceNote(${careGuideId}, ${resourceId}, ${noteId}, %j)`
    );
    return await this.DELETE(
      this._getUri(
        `/care-guide/${careGuideId}/resource/${resourceId}/note/${noteId}`
      )
    );
  }

  // GET /admin/partnership/${partnershipId}/resource/${resourceId}/notes/csv
  async getResourceNotesCSV(
    partnershipId = null,
    resourceId = null,
    order = null,
    filter = null
  ) {
    Logger.log('debug', `API.getResourceNotesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/admin/partnership/${partnershipId}/resource/${resourceId}/notes/csv`,
          { order_by: order, ...filter }
        )
      ),
      true
    );
  }

  // GET /media/image/upload-url
  async getMediaImageUploadURL(extension, directory) {
    Logger.log(
      'debug',
      `API.getMediaImageUploadURL(${extension}, ${directory})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/media/image/upload-url`, {
          extension: extension,
          directory: directory,
        })
      )
    );
  }

  // GET /media/image/signed-url
  async getMediaImageSignedURL(path) {
    Logger.log('debug', `API.getMediaImageSignedURL(${path})`);
    return await this.GET(
      this._getUri(QueryString.append(`/media/image/signed-url/${path}`))
    );
  }

  // POST /care-guide/{careGuideId}/media/image
  async postCareGuideMediaImage(careGuideId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideMediaImage(${careGuideId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/media/image`),
      payload
    );
  }

  // POST /care-guide/{careGuideId}/partnership/{partnershipId}/media/image
  async postCareGuidePartnershipMediaImage(
    careGuideId,
    partnershipId,
    payload
  ) {
    Logger.log(
      'debug',
      `API.postCareGuidePartnershipMediaImage(${careGuideId}, ${partnershipId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(
        `/care-guide/${careGuideId}/partnership/${partnershipId}/media/image`
      ),
      payload
    );
  }

  // POST /partnership/{partnershipId}/media/image
  async postPartnershipMediaImage(partnershipId, payload) {
    Logger.log(
      'debug',
      `API.postPartnershipMediaImage(${partnershipId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/partnership/${partnershipId}/media/image`),
      payload
    );
  }

  // DELETE /care-guide/{careGuideId}/media/image/{mediaId}
  async deleteCareGuideMediaImage(careGuideId, mediaId) {
    Logger.log(
      'debug',
      `API.deleteCareGuideMediaImage(${careGuideId}, ${mediaId})`
    );
    return await this.DELETE(
      this._getUri(`/care-guide/${careGuideId}/media/image/${mediaId}`)
    );
  }

  // DELETE /care-guide/{careGuideId}/partnership/{partnershipId}/media/image/{mediaId}
  async deleteCareGuidePartnershipMediaImage(
    careGuideId,
    partnershipId,
    mediaId
  ) {
    Logger.log(
      'debug',
      `API.deleteCareGuidePartnershipMediaImage(${careGuideId}, ${partnershipId}, ${mediaId})`
    );
    return await this.DELETE(
      this._getUri(
        `/care-guide/${careGuideId}/partnership/${partnershipId}/media/image/${mediaId}`
      )
    );
  }

  // DELETE /partnership/{partnershipId}/media/image/{mediaId}
  async deletePartnershipMediaImage(partnershipId, mediaId) {
    Logger.log(
      'debug',
      `API.deletePartnershipMediaImage(${partnershipId}, ${mediaId})`
    );
    return await this.DELETE(
      this._getUri(`/partnership/${partnershipId}/media/image/${mediaId}`)
    );
  }

  // GET /alerts
  async getAlerts(page = 1, limit = 50, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getAlerts(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/alerts/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // PATCH /alert/{alertId}
  async patchAlert(alertId, payload) {
    Logger.log('debug', `API.patchAlert(${alertId}, %j)`, payload);
    return await this.PATCH(this._getUri(`/alert/${alertId}`), payload);
  }

  // GET /care-guide/{careGuideId}/search
  async getCareGuideSearch(careGuideId, q, page = 1, limit = 10) {
    Logger.log(
      'debug',
      `API.getCareGuideSearch(${careGuideId}, ${q}, ${page}, ${limit})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/search/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { q: q }
        )
      )
    );
  }

  // GET /care-guide/{careGuideId}/journal/post/{journalPostId}/emojis
  async getCareGuideJournalPostEmojis(
    careGuideId,
    journalPostId,
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideJournalPostEmojis(${careGuideId}, ${journalPostId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/journal/post/${journalPostId}/emojis/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/journal/post/{journalPostId}/emojis
  async postCareGuideJournalPostEmojis(careGuideId, journalPostId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideJournalPostEmojis(${careGuideId}, ${journalPostId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(
        `/care-guide/${careGuideId}/journal/post/${journalPostId}/emojis`
      ),
      payload
    );
  }

  // DELETE /care-guide/{careGuideId}/journal/post/{journalPostId}/emoji/{journalEmojiId}
  async deleteCareGuideJournalPostEmoji(
    careGuideId,
    journalPostId,
    journalEmojiId
  ) {
    Logger.log(
      'debug',
      `API.deleteCareGuideMediaImage(${careGuideId}, ${journalPostId}, ${journalEmojiId})`
    );
    return await this.DELETE(
      this._getUri(
        `/care-guide/${careGuideId}/journal/post/${journalPostId}/emoji/${journalEmojiId}`
      )
    );
  }

  // POST /payments/customer
  async postPaymentsCustomer() {
    Logger.log('debug', `API.postPaymentsCustomer()`);
    return await this.POST(this._getUri(`/payments/customer`), {});
  }

  // PUT /payments/customer
  async putPaymentsCustomer(payload) {
    Logger.log('debug', `API.putPaymentsCustomer(%j)`, payload);
    return await this.PUT(this._getUri(`/payments/customer`), payload);
  }

  // POST /payments/subscription
  async postPaymentsSubscription(payload) {
    Logger.log('debug', `API.postPaymentsSubscription(%j)`, payload);
    return await this.POST(this._getUri(`/payments/subscription`), payload);
  }

  // GET /payments/subscription
  async getPaymentsSubscription() {
    Logger.log('debug', `API.getPaymentsSubscription()`);
    return await this.GET(
      this._getUri(QueryString.append(`/payments/subscription`))
    );
  }

  // DELETE /payments/subscription/{subscription_id}
  async deletePaymentsSubscription(subscriptionId) {
    Logger.log('debug', `API.deletePaymentsSubscription(${subscriptionId})`);
    return await this.DELETE(
      this._getUri(`/payments/subscription/${subscriptionId}`)
    );
  }

  // GET /plans
  async getPlans(page = 1, limit = 25, order = 'order.asc') {
    Logger.log('debug', `API.getPlans(${page}, ${limit}, ${order})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/plans/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
        })
      )
    );
  }

  // GET /regions
  async getRegions(countryCode, page = 1, limit = 25, order = 'name.asc') {
    Logger.log(
      'debug',
      `API.getRegions(${countryCode}, ${page}, ${limit}, ${order})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/regions/${countryCode}/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // GET /subregions
  async getSubregions(
    countryCode,
    regionCode,
    page = 1,
    limit = 25,
    order = 'name.asc'
  ) {
    Logger.log(
      'debug',
      `API.getSubregions(${countryCode}, ${regionCode}, ${page}, ${limit}, ${order})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/subregions/${countryCode}/${regionCode}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // GET /cities
  async getCities(
    countryCode,
    regionCode,
    page = 1,
    limit = 25,
    order = 'name.asc'
  ) {
    Logger.log(
      'debug',
      `API.getCities(${countryCode}, ${regionCode}, ${page}, ${limit}, ${order})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/cities/${countryCode}/${regionCode}/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order }
        )
      )
    );
  }

  // GET /postal-codes
  async getPostalCodes(
    countryCode,
    regionCode,
    parent,
    parentID,
    page = 1,
    limit = 25,
    order = 'postal_code.asc'
  ) {
    Logger.log(
      'debug',
      `API.getPostalCodes(${countryCode}, ${regionCode}, ${parent}, ${parentID}, ${page}, ${limit}, ${order})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/postal-codes/${countryCode}/${regionCode}/${parent}/${parseInt(
            parentID
          )}/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // GET /user-profile-partnerships
  async getUserProfilesPartnerships(
    page = 1,
    limit = 50,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getUserProfilesPartnerships(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/user-profile-partnerships/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /user-profiles-partnership/partnership/${partnership_id}
  async getUserProfilesPartnership(partnership_id) {
    Logger.log('debug', `API.getUserProfilesPartnership(${partnership_id})`);
    return await this.GET(
      this._getUri(`/user-profiles-partnership/partnership/${partnership_id}`)
    );
  }

  // GET /registration-codes
  async getRegistrationCodes(
    partnership_id = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getRegistrationCodes(${partnership_id}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/registration-codes/${partnership_id}/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /registration-code/{id}
  async getRegistrationCode(partnership_id, id) {
    Logger.log('debug', `API.getRegistrationCode(${partnership_id}, ${id})`);
    return await this.GET(
      this._getUri(`/registration-code/${partnership_id}/code/${id}`)
    );
  }

  // PUT /registration-code/{id}
  async putRegistrationCode(id, payload) {
    Logger.log('debug', `API.putRegistrationCode(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/registration-code/${id}`), payload);
  }

  // DELETE /registration-code/{id}
  async deleteRegistrationCode(partnership_id, id) {
    Logger.log('debug', `API.deleteRegistrationCode(${partnership_id}, ${id})`);
    return await this.DELETE(
      this._getUri(`/registration-code/${partnership_id}/code/${id}`)
    );
  }

  // GET /partnerships
  async getPartnerships(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getPartnerships(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnerships/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /plans-admin
  async getPlansAdmin(page = 1, limit = 10, order = null) {
    Logger.log('debug', `API.getPlansAdmin(${page}, ${limit}, ${order})`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/plans-admin/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // GET /journal-partner-tags-admin
  async getJournalPartnerTagsAdmin(
    partnership_id = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getJournalPartnerTagsAdmin(${partnership_id}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/journal-partner-tags-admin/${partnership_id}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /journal-partner-tag-admin/{id}
  async getJournalPartnerTagAdmin(partnership_id, id) {
    Logger.log(
      'debug',
      `API.getJournalPartnerTagAdmin(${partnership_id}, ${id})`
    );
    return await this.GET(
      this._getUri(
        `/journal-partner-tag-admin/${partnership_id}/journal-tag/${id}`
      )
    );
  }

  // POST /journal-partner-tag-admin
  async postJournalPartnerTagsAdmin(payload) {
    Logger.log('debug', `API.postJournalPartnerTagsAdmin(%j)`, payload);
    return await this.POST(this._getUri(`/journal-partner-tag-admin`), payload);
  }

  // PUT /journal-partner-tag-admin/{id}
  async putJournalPartnerTagAdmin(id, payload) {
    Logger.log('debug', `API.putJournalPartnerTagAdmin(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/journal-partner-tag-admin/${id}`),
      payload
    );
  }

  // DELETE /journal-partner-tag-admin/{id}
  async deleteJournalPartnerTagAdmin(partnership_id, id) {
    Logger.log(
      'debug',
      `API.deleteJournalPartnerTagAdmin(${partnership_id}, ${id})`
    );
    return await this.DELETE(
      this._getUri(
        `/journal-partner-tag-admin/${partnership_id}/journal-tag/${id}`
      )
    );
  }

  // GET /journal-partner-tags
  async getJournalPartnerTags(
    care_guide_id = null,
    page = 1,
    limit = 500,
    order = 'label.asc',
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getJournalTags(${care_guide_id},${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/journal-partner-tags/${care_guide_id}/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /user-profiles-partnerships
  async getUserProfilesPartnershipsAdmin(
    partnership_id = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getUserProfilesPartnershipsAdmin(${partnership_id}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/user-profile-partnerships-admin/${partnership_id}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-owners/all_ids
  async getCareGuidesAllIds(partnership_id = null, filter = null) {
    Logger.log('debug', 'API.getCareGuides all ids');
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnership/${partnership_id}/care-guides/all_ids`,
          { ...filter }
        )
      )
    );
  }

  // GET /care-guide-owners-filters
  async getCareGuideOwnersFilters(partnership_id = null) {
    Logger.log('debug', `API.getCareGuideOwnersFilters(${partnership_id})`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/filters-admin-partner-classes/partnership/${partnership_id}`
        )
      )
    );
  }

  // GET /calendar-events-admin
  async getCalendarEventsAdmin(
    partnership_id = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCalendarEventsAdmin(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/calendar-events-admin/${partnership_id}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /calendar-event-admin/{id}
  async getCalendarEventAdmin(partnership_id, id) {
    Logger.log('debug', `API.getCalendarEventAdmin(${partnership_id}, ${id})`);
    return await this.GET(
      this._getUri(`/calendar-event-admin/${partnership_id}/event/${id}`)
    );
  }

  // POST /calendar-events-admin
  async postCalendarEventsAdmin(payload) {
    Logger.log('debug', `API.postCalendarEventsAdmin(%j)`, payload);
    return await this.POST(this._getUri(`/calendar-events-admin`), payload);
  }

  // PUT /calendar-event-admin/{id}
  async putCalendarEventAdmin(id, payload) {
    Logger.log('debug', `API.putCalendarEventAdmin(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/calendar-event-admin/${id}`), payload);
  }

  // DELETE /calendar-event-admin/{id}
  async deleteCalendarEventAdmin(partnershipId, id) {
    Logger.log(
      'debug',
      `API.deleteCalendarEventAdmin(${partnershipId}, ${id})`
    );
    return await this.DELETE(
      this._getUri(`/calendar-event-admin/${partnershipId}/event/${id}`)
    );
  }

  // GET /care-guide/{careGuideId}/members-admin
  async getCareGuideMembersAdmin(filter = null) {
    Logger.log('debug', `API.getCareGuideMembersAdmin(%j)`, filter);
    return await this.GET(
      this._getUri(
        QueryString.append('/care-guides/members-admin', { ...filter })
      )
    );
  }

  // GET /care-guide-members-partnership/${id}
  async getCareGuidesMembersPartnership(partnershipId) {
    Logger.log(
      'debug',
      `API.getCareGuidesMembersPartnership(${partnershipId} %j)`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-members-partnership/${partnershipId}`,
          {}
        )
      )
    );
  }

  // GET /care-guides-partnership/${id}
  async getCareGuidesPartnership(partnershipId, page, limit, order, filter) {
    Logger.log(
      'debug',
      `API.getCareGuidesPartnership(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guides-partnership/${partnershipId}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /partnership_account/${id}
  async getPartnershipAccount(partnership_id) {
    Logger.log('debug', `API.gePartnershipAccount(${partnership_id})`);
    return await this.GET(
      this._getUri(`/partnership_account/${partnership_id}`)
    );
  }

  // PUT /partnership_account/${id}
  async putPartnershipAccount(partnership_id, payload) {
    Logger.log(
      'debug',
      `API.putPartnershipAccount(${partnership_id}, ###)`,
      payload
    );
    return await this.PUT(
      this._getUri(`/partnership_account/${partnership_id}`),
      payload
    );
  }

  // POST /partnership_account/logo/${id}
  async postPartnershipAccountLogo(partnership_id, payload) {
    Logger.log(
      'debug',
      `API.postPartnershipAccountLogo(${partnership_id}, ###)`
    );
    return await this.POST(
      this._getUri(`/partnership_account/logo/${partnership_id}`),
      payload
    );
  }

  // DELETE /partnership_account/logo/${id}
  async deletePartnershipAccountLogo(partnership_id) {
    Logger.log('debug', `API.deletePartnershipAccountLogo(${partnership_id})`);
    return await this.DELETE(
      this._getUri(`/partnership_account/logo/${partnership_id}`)
    );
  }

  // PUT /partnership_account/access/${id}
  async putPartnershipAccountAccessAdmin(partnership_id, payload) {
    Logger.log(
      'debug',
      `API.putPartnershipAccountAccessAdmin(${partnership_id}, ###)`,
      payload
    );
    return await this.PUT(
      this._getUri(`/partnership_account/access/${partnership_id}`),
      payload
    );
  }

  // PUT /partnership_account/access-care-guide/${id}
  async putPartnershipAccountAccessCareGuideAdmin(partnership_id, payload) {
    Logger.log(
      'debug',
      `API.putPartnershipAccountAccessCareGuideAdmin(${partnership_id}, ###)`,
      payload
    );
    return await this.PUT(
      this._getUri(`/partnership_account/access-care-guide/${partnership_id}`),
      payload
    );
  }

  // GET /resources-admin/partnership/{id}
  async getResourcesAdmin(
    partnershipId = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getResourcesAdmin(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resources-admin/partnership/${partnershipId}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET  /resources-admin/partnership/{id}/pinned
  async getResourcesPinnedAdmin(
    partnershipId = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getResourcesPinnedAdmin(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resources-admin/partnership/${partnershipId}/pinned/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET  /resources-admin/partnership/{id}/dashboard
  async getResourcesDashboardAdmin(
    partnershipId = null,
    page = 1,
    limit = 10,
    order = null
  ) {
    Logger.log(
      'debug',
      `API.getResourcesDashboardAdmin(${partnershipId}, ${page}, ${limit}, ${order}, %j)`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resources-admin/partnership/${partnershipId}/dashboard/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // GET /resource-admin/partnership/${partnershipId}/ids
  async getResourceIdsAdmin(partnership_id = null, filter = null) {
    Logger.log('debug', 'API.getResourceIdsAdmin');
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resource-admin/partnership/${partnership_id}/ids`,
          { ...filter }
        )
      )
    );
  }

  // GET /resource-admin/{id}
  async getResourceAdmin(partnership_id, id) {
    Logger.log('debug', `API.getResourceAdmin(${partnership_id}, ${id})`);
    return await this.GET(
      this._getUri(`/resource-admin/${partnership_id}/resource/${id}`)
    );
  }

  // POST /resources-admin
  async postResourcesAdmin(payload) {
    Logger.log('debug', `API.postResourcesAdmin(%j)`, payload);
    return await this.POST(this._getUri(`/resources-admin`), payload);
  }

  // PUT /resource-admin/{id}
  async putResourceAdmin(id, payload) {
    Logger.log('debug', `API.putResourceAdmin(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/resource-admin/${id}`), payload);
  }

  // PATCH /resource-admin/partnership/{partnershipId}/resource/{resourceId}
  async patchResourceAdmin(partnershipId, resourceId, payload) {
    Logger.log(
      'debug',
      `API.patchResourceAdmin(${partnershipId}, ${resourceId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(
        `/resource-admin/partnership/${partnershipId}/resource/${resourceId}`
      ),
      payload
    );
  }

  // PATCH /resource-admin/partnership/{partnershipId}/resource/{resourceId}
  async patchResourcesAdmin(partnershipId, payload) {
    Logger.log(
      'debug',
      `API.patchResourcesAdmin(${partnershipId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(`/resource-admin/partnership/${partnershipId}/resources`),
      payload
    );
  }

  // DELETE /resource-admin/{id}
  async deleteResourceAdmin(partnershipId, resourceId) {
    Logger.log(
      'debug',
      `API.deleteResourceAdmin(${partnershipId}, ${resourceId})`
    );
    return await this.DELETE(
      this._getUri(
        `/resource-admin/partnership/${partnershipId}/resource/${resourceId}`
      )
    );
  }

  // DELETE /resource-admin/partnership/${partnershipId}/delete
  async deleteResourcesAdmin(partnershipId, payload) {
    Logger.log(
      'debug',
      `API.deleteResourcesAdmin(${partnershipId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/resources-admin/partnership/${partnershipId}/delete`),
      payload
    );
  }

  // GET /care-guide-partner-tags-admin
  async getCareGuidePartnerTagsAdmin(
    partnership_id = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuidePartnerTagsAdmin(${partnership_id}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-partner-tags-admin/${partnership_id}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-partner-tag-admin/{id}
  async getCareGuidePartnerTagAdmin(partnership_id, id) {
    Logger.log(
      'debug',
      `API.getCareGuidePartnerTagAdmin(${partnership_id}, ${id})`
    );
    return await this.GET(
      this._getUri(
        `/care-guide-partner-tag-admin/${partnership_id}/care-guide-tag/${id}`
      )
    );
  }

  // POST /care-guide-partner-tag-admin
  async postCareGuidePartnerTagsAdmin(payload) {
    Logger.log('debug', `API.postCareGuidePartnerTagsAdmin(%j)`, payload);
    return await this.POST(
      this._getUri(`/care-guide-partner-tag-admin`),
      payload
    );
  }

  // PUT /care-guide-partner-tag-admin/{id}
  async putCareGuidePartnerTagAdmin(id, payload) {
    Logger.log('debug', `API.putCareGuidePartnerTagAdmin(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/care-guide-partner-tag-admin/${id}`),
      payload
    );
  }

  // DELETE /care-guide-partner-tag-admin/{id}
  async deleteCareGuidePartnerTagAdmin(partnership_id, id) {
    Logger.log(
      'debug',
      `API.deleteCareGuidePartnerTagAdmin(${partnership_id}, ${id})`
    );
    return await this.DELETE(
      this._getUri(
        `/care-guide-partner-tag-admin/${partnership_id}/care-guide-tag/${id}`
      )
    );
  }

  // GET /care-guide-partner-tag-admin/{id}/care-partner-tags
  async getCareGuidePartnerTagPartnershipAdmin(id = null) {
    Logger.log('debug', `API.getCareGuidePartnerTagPartnershipAdmin(${id})`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-partner-tag-admin/${id}/care-partner-tags`
        )
      )
    );
  }

  // GET /care-guide-partner-tag/{id}/care-partner-tags
  async getCareGuidePartnerTagPartnershipHome(id = null) {
    Logger.log('debug', `API.getCareGuidePartnerTagPartnershipHome(${id})`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-partner-tag/${id}/care-partner-tags/home`
        )
      )
    );
  }

  // GET /care-guide-admin/{id}
  async getCareGuideAdmin(partnership_id, id) {
    Logger.log('debug', `API.getCareGuideAdmin(${partnership_id}, ${id})`);
    return await this.GET(
      this._getUri(`/care-guide-admin/${partnership_id}/care-guide/${id}`)
    );
  }

  // PUT /care-guide-admin/{id}
  async putCareGuideAdmin(partnership_id, id, payload) {
    Logger.log('debug', `API.putCareGuideAdmin(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/care-guide-admin/${partnership_id}/care-guide/${id}`),
      payload
    );
  }

  // GET /admin-partner-classes
  async getAdminPartnerClasses(
    partnershipId,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getAdminPartnerClasses(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/admin-partner-classes/partnership/${partnershipId}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /partnership/{id}/admin-partner-classes
  async getAdminPartnerClassesRegister(partnershipId = null) {
    Logger.log('debug', `API.getAdminPartnerClassesRegister(${partnershipId})`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnership/${partnershipId}/admin-partner-classes/register`
        )
      ),
      false,
      { 'X-API-KEY': this.x_api_key }
    );
  }

  // GET /partnership/{id}/admin-partner-classes/admin
  async getAdminPartnerClassesAdmin(partnershipId = null) {
    Logger.log('debug', `API.getAdminPartnerClassesAdmin(${partnershipId})`);
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnership/${partnershipId}/admin-partner-classes/admin`
        )
      )
    );
  }

  // GET /partnership/${id}/care-guide-owners/csv
  async postCareGuidesCSV(
    partnershipId = null,
    payload = null,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuidesCSV(${partnershipId}, ${payload}, ${order}, ${filter})`
    );
    return await this.POST(
      this._getUri(
        QueryString.append(`/partnership/${partnershipId}/care-guides/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      payload
    );
  }

  // GET /care-guide/{careGuideId}/pdf
  async postCareGuideNotesPDF(
    careGuideId = null,
    payload = null,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.postCareGuideNotesPDF(${careGuideId}, ${payload}, ${order}, ${filter})`
    );
    return await this.POST(
      this._getUri(
        QueryString.append(`/care-guide/${careGuideId}/care-guide-notes/pdf`, {
          order_by: order,
          ...filter,
        })
      ),
      payload
    );
  }

  // GET /care-guide/${id}/care-guide-notes/
  async getCareGuideNotes(
    careGuideId = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideNotes(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/care-guide-notes/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // PATCH /care-guide/{id}/care-guide-note/{id}
  async patchCareGuideNote(careGuideId, careGuideNoteId, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideNote(${careGuideId}, ${careGuideNoteId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(
        `/care-guide/${careGuideId}/care-guide-note/${careGuideNoteId}`
      ),
      payload
    );
  }

  // DELETE /care-guide/{id}/care-guide-note/{id}
  async deleteCareGuideNote(careGuideId, id) {
    Logger.log('debug', `API.deleteCareGuideNote(${careGuideId}, ${id})`);
    return await this.DELETE(
      this._getUri(`/care-guide/${careGuideId}/care-guide-note/${id}`)
    );
  }

  // GET /care-guide/{id}/care-guide-documents
  async getCareGuideDocuments(
    careGuideId = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideDocuments(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/care-guide-documents/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // POST /care-guide/{id}/care-guide-document
  async postCareGuideDocument(careGuideId, payload) {
    Logger.log(
      'debug',
      `API.postCareGuideDocument(${careGuideId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/care-guide-document`),
      payload
    );
  }

  // PUT /care-guide/{id}/care-guide-document/{care-guide-document-id}
  async putCareGuideDocument(careGuideId, documentId, payload) {
    Logger.log(
      'debug',
      `API.putCareGuideDocument(${careGuideId}, ${documentId}, %j)`,
      payload
    );
    return await this.PUT(
      this._getUri(
        `/care-guide/${careGuideId}/care-guide-document/${documentId}`
      ),
      payload
    );
  }

  // PATCH /care-guide/{id}/care-guide-document/{care-guide-document-id}
  async patchCareGuideDocument(careGuideId, documentId, payload) {
    Logger.log(
      'debug',
      `API.patchCareGuideDocument(${careGuideId}, ${documentId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(
        `/care-guide/${careGuideId}/care-guide-document/${documentId}`
      ),
      payload
    );
  }

  // GET /partnership/${id}/care-guide-documents/csv
  async postCareGuideDocumentsCSV(
    careGuideId = null,
    payload = null,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuidesCSV(${careGuideId}, ${payload}, ${order}, ${filter})`
    );
    return await this.POST(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/care-guide-documents/csv`,
          { order_by: order, ...filter }
        )
      ),
      payload
    );
  }

  // POST /care-guide/{id}/care-guide-note/{id}
  async postCareGuideNote(careGuideId, payload) {
    Logger.log('debug', `API.postCareGuideNote(${careGuideId}, %j)`, payload);
    return await this.POST(
      this._getUri(`/care-guide/${careGuideId}/care-guide-note/`),
      payload
    );
  }

  // PUT /care-guide/{id}/care-guide-note/{id}
  async putCareGuideNote(careGuideId, careGuideNoteId, payload) {
    Logger.log(
      'debug',
      `API.putCareGuideNote(${careGuideId}, ${careGuideNoteId}, %j)`,
      payload
    );
    return await this.PUT(
      this._getUri(
        `/care-guide/${careGuideId}/care-guide-note/${careGuideNoteId}`
      ),
      payload
    );
  }

  // GET /partnership_form_answers
  async getPartnershipFormAnswers(
    partnershipId = null,
    careGuideId = null,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getPartnershipFormAnswers(${partnershipId}, ${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnership_form_answers/partnership/${partnershipId}/care-guide/${careGuideId}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /partnership_form_answers/partnership/{id}/care-guide/{id}/partnership-form-answer/{id}/pdf
  async getPartnershipFormAnswerPDF(partnershipId, careGuideId, id) {
    Logger.log(
      'debug',
      `API.getPartnershipFormAnswerPDF(${partnershipId}, ${careGuideId}, ${id})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnership_form_answers/partnership/${partnershipId}/care-guide/${careGuideId}/partnership-form-answer/${id}/pdf`
        )
      )
    );
  }

  // DELETE /partnership_form_answers/partnership/{id}/care-guide/{id}/partnership-form-answer/{id}
  async deletePartnershipFormAnswer(partnershipId, careGuideId, id) {
    Logger.log(
      'debug',
      `API.deletePartnershipFormAnswer(${partnershipId}, ${careGuideId}, ${id})`
    );
    return await this.DELETE(
      this._getUri(
        `/partnership_form_answers/partnership/${partnershipId}/care-guide/${careGuideId}/partnership-form-answer/${id}`
      )
    );
  }

  // GET /dashboard-sections
  async getDashboardSections(
    partnershipId = null,
    page = 1,
    limit = 10,
    order = null
  ) {
    Logger.log(
      'debug',
      `API.getDashboardSections(${partnershipId}, ${page}, ${limit}, ${order})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/dashboard-sections/partnership/${partnershipId}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // PATCH /care-guide/{id}
  async patchDashboardSection(partnershipId, id, payload) {
    Logger.log(
      'debug',
      `API.patchDashboardSection(${partnershipId}, ${id}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(
        `/dashboard-sections/partnership/${partnershipId}/dashboard-section/${id}`
      ),
      payload
    );
  }

  // GET /recommended-utilities/partnership/{id}
  async getRecommendedUtilities(
    partnershipId = null,
    careGuideId = null,
    page = 1,
    limit = 10,
    order = null
  ) {
    Logger.log(
      'debug',
      `API.getRecommendedUtilities(${partnershipId}, ${careGuideId}, ${page}, ${limit}, ${order})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/recommended-utilities/partnership/${partnershipId}/care_guide/${careGuideId}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // GET /recommended-utilities-admin/partnership/{id}
  async getRecommendedUtilitiesAdmin(
    partnershipId = null,
    page = 1,
    limit = 10,
    order = null
  ) {
    Logger.log(
      'debug',
      `API.getRecommendedUtilitiesAdmin(${partnershipId}, ${page}, ${limit}, ${order})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/recommended-utilities-admin/partnership/${partnershipId}/${parseInt(
            page
          )}/${parseInt(limit)}`,
          { order_by: order }
        )
      )
    );
  }

  // POST /recommended-utilities-admin/partnership/{id}/recommended-utility
  async postRecommendedUtilityAdmin(partnershipId, payload) {
    Logger.log(
      'debug',
      `API.postRecommendedUtilityAdmin(${partnershipId})`,
      payload
    );
    return await this.POST(
      this._getUri(
        `/recommended-utilities-admin/partnership/${partnershipId}/recommended-utility`
      ),
      payload
    );
  }

  // PUT /recommended-utilities-admin/partnership/{id}/recommended-utility/{id}
  async putRecommendedUtilityAdmin(
    partnershipId,
    recommendedUtilityId,
    payload
  ) {
    Logger.log(
      'debug',
      `API.putRecommendedUtilityAdmin(${partnershipId}, ${recommendedUtilityId}, %j)`,
      payload
    );
    return await this.PUT(
      this._getUri(
        `/recommended-utilities-admin/partnership/${partnershipId}/recommended-utility/${recommendedUtilityId}`
      ),
      payload
    );
  }

  // PATCH /recommended-utilities-admin/partnership/{id}/recommended-utility/{id}
  async patchRecommendedUtilityAdmin(
    partnershipId,
    recommendedUtilityId,
    payload
  ) {
    Logger.log(
      'debug',
      `API.patchRecommendedUtilityAdmin(${partnershipId}, ${recommendedUtilityId}, %j)`,
      payload
    );
    return await this.PATCH(
      this._getUri(
        `/recommended-utilities-admin/partnership/${partnershipId}/recommended-utility/${recommendedUtilityId}`
      ),
      payload
    );
  }

  // DELETE /recommended-utilities-admin/partnership/{id}/recommended-utility/{id}
  async deleteRecommendedUtilityAdmin(partnershipId, recommendedUtilityId) {
    Logger.log(
      'debug',
      `API.deleteRecommendedUtilityAdmin(${partnershipId}, ${recommendedUtilityId})`
    );
    return await this.DELETE(
      this._getUri(
        `/recommended-utilities-admin/partnership/${partnershipId}/recommended-utility/${recommendedUtilityId}`
      )
    );
  }

  // GET /file/upload-url
  async getFileUploadURL(extension, directory) {
    Logger.log('debug', `API.getFileUploadURL(${extension}, ${directory})`);

    return await this.GET(
      this._getUri(
        QueryString.append(`/file/upload-url`, {
          directory: directory,
          extension: extension,
        })
      )
    );
  }

  // GET /file/download-url
  async getFileDownloadURL(fileKey, fileName) {
    Logger.log('debug', `API.getFileDownloadURL(${fileKey}, ${fileName})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/file/download-url`, {
          file_key: fileKey,
          file_name: fileName,
        })
      )
    );
  }

  // DELETE /file/delete
  async deleteFile(fileKey) {
    Logger.log('debug', `API.deleteFile(${fileKey})`);
    return await this.DELETE(
      this._getUri(
        QueryString.append(`/file/delete`, {
          file_key: fileKey,
        })
      )
    );
  }

  // Strapi Routes

  // GET /topic-learns
  async getTopics(plan_name, page, limit) {
    Logger.log('debug', `API.getTopics(${page}, ${limit}, %j)`);
    return await this.GETStrapi(
      this._getUriStrapi(
        QueryStringStrapi.append(`/topic-learns`, {
          sort: {
            0: 'Order',
          },
          pagination: {
            page: page,
            pageSize: limit,
          },
          populate: {
            Videos: { populate: '*' },
            ImageFile: { populate: '*' },
            Plans: {
              populate: '*',
            },
            subTopics: {
              populate: {
                Videos: { populate: '*' },
                ImageFile: { populate: '*' },
              },
              sort: {
                1: 'Order',
              },
            },
          },
          filters: {
            Plans: {
              Name: {
                $eq: plan_name,
              },
            },
          },
        })
      )
    );
  }

  // GET /partnership_account/${id}}
  async getSubTopic(id) {
    Logger.log('debug', `API.getSubTopic(${id})`);
    return await this.GETStrapi(
      this._getUriStrapi(
        QueryStringStrapi.append(`/sub-topic-learns/${id}`, {
          populate: {
            Videos: {
              populate: '*',
            },
          },
        })
      )
    );
  }

  // External API Calls

  // PUT image to S3 bucket
  async putImageUpload(path, file) {
    Logger.log('debug', `API.putImageUpload(${path}, ###)`);

    const requestHeaders = {
      headers: {
        'Content-Type': file.type,
      },
    };

    return await this.axios
      .put(path, file, requestHeaders)
      .then(async function (response) {
        Logger.log('verbose', `S3 Response:`, response);
        return Map(response);
      })
      .catch(function (error) {
        Logger.log('error', `${error}`);
        return Map({ status: 500, data: { error: `${error}` } });
      });
  }

  // PUT File to S3 bucket
  async putFileUpload(path, file) {
    Logger.log('debug', `API.putFileUpload(${path}, ###)`);

    const requestHeaders = {
      headers: {
        'Content-Type': file.type,
      },
    };

    return await this.axios
      .put(path, file, requestHeaders)
      .then(async function (response) {
        Logger.log('verbose', `S3 Response:`, response);
        return Map(response);
      })
      .catch(function (error) {
        Logger.log('error', `${error}`);
        return Map({ status: 500, data: { error: `${error}` } });
      });
  }
}

const api = new API(Config, axios);
export default api;

Logger.log('silly', `API loaded.`);
