import { connect } from 'react-redux';
import {
  getImageSignedURL,
  setActiveImage,
} from '../../../../../state/modules/media/actions';
import Format from '../../../../../lib/Format';

import JournalSignedImage from '../components/JournalSignedImage';

const mapStateToProps = (state, ownProps) => {
  const sizeThumb = ownProps.sizeThumb ?? 160;
  const sizePreview = ownProps.sizePreview ?? 1200;
  const item = ownProps.item ?? null;

  const thumbPath = Format.buildImagePath('ratio', sizeThumb, sizeThumb, item);
  const previewPath = Format.buildImagePath(
    'ratio',
    sizePreview,
    sizePreview,
    item
  );

  const imageThumbUrl = state.media.getIn(['images', thumbPath]) ?? null;
  const imagePreviewUrl = state.media.getIn(['images', previewPath]) ?? null;

  return {
    sizeThumb: sizeThumb,
    sizePreview: sizePreview,
    thumbPath: thumbPath,
    previewPath: previewPath,
    imageThumbUrl: imageThumbUrl,
    imagePreviewUrl: imagePreviewUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const JournalSignedImageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalSignedImage);

export default JournalSignedImageContainer;
