import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import {
  Button,
  Comment,
  List,
  Typography,
  Dropdown,
  Menu,
  Space,
  Form,
} from 'antd';
import {
  ReloadOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { FaEllipsisV } from 'react-icons/fa';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import notification from '../../../elements/lib/NotificationWrapper';

import UserAvatar from '../../../elements/containers/UserAvatarContainer';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import Config from '../../../../../Config';
import BodyInput from './BodyInput';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const { Title, Text } = Typography;

const ResourceNotesList = ({
  careGuideId,
  careGuide,
  partnershipId,
  partnershipName,
  resourceId,
  resource,
  component,
  isSubmittingEdit,
  page,
  limit,
  order,
  total,
  load,
  history,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editNoteId, setEditNoteId] = useState(null);
  const [resetBody, setResetBody] = useState(null);

  const [form] = Form.useForm();

  const trackerResource = (event, params = {}) => {
    if (careGuide?.partnership) {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        partnershipName: careGuide.partnership.name,
        careGuideId: careGuide.id,
        partnershipId: careGuide.partnership.id,
        resourceId: resource.id,
        resourceName: resource.name,
        ...params,
      });
    } else {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        careGuideId: careGuide.id,
        resourceId: resource.id,
        resourceName: resource.name,
        ...params,
      });
    }
  };

  useEffect(() => {
    if (careGuideId && resourceId) {
      load(careGuideId, resourceId, page, limit, order, null, () => {
        setIsLoading(false);
      });
    }
  }, [careGuideId, resourceId, page, limit, order, load]);

  const onLoadMoreClick = () => {
    setIsLoading(true);
    props.setPage(page + 1);
  };

  // using ternary to keep button visible while loading
  const showLoadMore = isLoading
    ? Math.ceil((page - 1) * limit) < total
    : Math.ceil(page * limit) < total;

  const handleEditNote = (id, body = '') => {
    setEditNoteId(id);
    setResetBody(null);
    form.setFieldsValue({ body: body });
  };

  const handleDeleteNote = (item) => {
    props.delete(careGuideId, resourceId, item?.id, (success) => {
      if (success) {
        notification.success(getI18n().t('resource_notes_deleted'));
        setEditNoteId(null);
        setResetBody(null);
        form.setFieldsValue({ body: '' });
        trackerResource(mixpanelEventsEnum.RESOURCE.DELETE_NOTE);
        if (careGuideId && resourceId) {
          load(careGuideId, resourceId, page, limit, order, null, () => {
            setIsLoading(false);
          });
        }
      } else {
        notification.error(getI18n().t('resource_notes_failure_deleted'));
      }
    });
  };

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `ResourceNoteForm.submitData(###)`);

    const payload = {};

    // transform values
    for (var key in values) {
      payload[key] = values[key];
    }

    props.patch(careGuideId, resourceId, editNoteId, payload, (success) => {
      if (success) {
        notification.success(getI18n().t('resource_notes_edited'));
        form.setFieldsValue({ body: '' });
        setResetBody(Math.random());
        setEditNoteId(null);
        trackerResource(mixpanelEventsEnum.RESOURCE.EDIT_NOTE);
      } else {
        notification.error(getI18n().t('resource_notes_failure_edited'));
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `ResourceNoteForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `ResourceNoteForm.handleFinishFailed(###)`);
    notification.error(getI18n().t('resource_notes_failure_edited'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    for (const key of Object.keys(changedValues)) {
      form.setFields([{ name: key, errors: [] }]);
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Title level={5}>{t('resource_notes_list_subtitle')}</Title>
          {total ? (
            <List
              className='resource-notes-list'
              itemLayout='vertical'
              dataSource={props.list}
              pagination={false}
              renderItem={(item) => {
                return (
                  <List.Item key={item.id}>
                    <Comment
                      author={
                        <Space direction='vertical'>
                          <Text>
                            {item?.profile?.first_name}{' '}
                            {item?.profile?.last_name}
                          </Text>
                        </Space>
                      }
                      avatar={<UserAvatar profile={item.profile} />}
                      content={
                        editNoteId === item.id ? (
                          <>
                            <Form
                              name='note_edit_form'
                              form={form}
                              onFinish={handleFinish}
                              onFinishFailed={handleFinishFailed}
                              onValuesChange={handleValuesChange}
                              validateTrigger='onSubmit'
                              requiredMark={false}
                              layout='vertical'
                            >
                              <BodyInput
                                name='body'
                                className={'resource-note-form-edit-body'}
                                defaultValue={item.body}
                                form={form}
                                placeholder={t(
                                  'resource_notes_form_body_placeholder'
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t('feedback_validation_required'),
                                  },
                                  {
                                    type: 'string',
                                    min: 1,
                                    max: 4000,
                                    message: t(
                                      'feedback_validation_char_range',
                                      {
                                        min: 1,
                                        max: 4000,
                                      }
                                    ),
                                  },
                                ]}
                                disabled={
                                  isLoading ||
                                  isSubmittingEdit ||
                                  props.isLoading
                                }
                                reset={resetBody}
                              />

                              <Space>
                                <Button
                                  type='primary'
                                  size='small'
                                  htmlType='submit'
                                >
                                  {t('resource_notes_form_body_btn_edit')}
                                </Button>
                                <Button
                                  size='small'
                                  onClick={() => handleEditNote(null)}
                                >
                                  {t('resource_notes_form_body_btn_cancel')}
                                </Button>
                              </Space>
                            </Form>
                          </>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{ __html: item?.body }}
                          />
                        )
                      }
                      datetime={
                        <>
                          {Format.date(
                            item?.published_at,
                            Config.get('DEFAULT_DATETIME_FORMAT')
                          )}
                          {props.profileId === item?.profile?.id ? (
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key={'edit-' + item?.id}
                                    icon={<EditOutlined />}
                                    onClick={() =>
                                      handleEditNote(item?.id, item?.body)
                                    }
                                  >
                                    {t('action_edit')}
                                  </Menu.Item>
                                  <Menu.Item
                                    key={'delete-' + item?.id}
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleDeleteNote(item)}
                                  >
                                    {t('action_delete')}
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={['click']}
                            >
                              <span className='anticon' tabIndex={-1}>
                                <FaEllipsisV />
                              </span>
                            </Dropdown>
                          ) : null}
                        </>
                      }
                    />
                  </List.Item>
                );
              }}
            />
          ) : (
            <div className='resource-notes-list-empty'>
              <FontAwesomeIcon icon={faBook} size='2x' />
              <div style={{ marginTop: '10px' }}>
                <Text type='secondary'>{t('resource_notes_list_empty')}</Text>
              </div>
            </div>
          )}

          {showLoadMore ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                size='small'
                onClick={onLoadMoreClick}
                icon={<ReloadOutlined />}
                loading={isLoading}
              >
                {t('action_load_more')}
              </Button>
            </div>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourceNotesList);

Logger.log('silly', `ResourceNotesList loaded.`);
