import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Card, Dropdown, Menu, Modal, Tag, Image } from 'antd';
import {
  CloseCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { BiBox } from 'react-icons/bi';
import { FaEllipsisV } from 'react-icons/fa';

import RatingDisplay from './RatingDisplay';
import JournalRepliesList from '../../journalReplies/containers/JournalRepliesListContainer';
import JournalEmojisList from '../../journalEmojis/containers/JournalEmojisListContainer';
import UserAvatar from '../../../elements/containers/UserAvatarContainer';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import message from '../../../elements/lib/MessageWrapper';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import Config from '../../../../../Config';
import JournalSignedImage from '../containers/JournalSignedImageContainer';

const archiveFlag = false;

const JournalPostDetail = ({
  careGuideId,
  journal,
  page,
  limit,
  order,
  ...props
}) => {
  const [commentsPage, setCommentsPage] = useState(1);

  const { width } = useWindowDimensions();
  const isLarge = width > 992;

  const onClickEditHandler = (e) => {
    props.showForm(journal.id);
  };

  const onClickArchiveHandler = (e) => {
    props.patch(careGuideId, journal.id, { public_status: 2 }, (success) => {
      if (success) {
        message.success(getI18n().t('feedback_archive_success'));
        props.load(careGuideId, page, limit, order);
      } else {
        message.error(getI18n().t('feedback_archive_error'));
      }
    });
  };

  const confirmArchive = () => {
    Modal.confirm({
      title: getI18n().t('journal_posts_archive_confirm_title'),
      icon: <ExclamationCircleOutlined />,
      content: getI18n().t('journal_posts_archive_confirm_body'),
      okType: 'danger',
      okText: getI18n().t('action_archive'),
      okButtonProps: {
        icon: (
          <span className='anticon'>
            <BiBox />
          </span>
        ),
      },
      cancelText: getI18n().t('confirm_cancel'),
      cancelButtonProps: {
        icon: <CloseCircleOutlined />,
      },
      onOk() {
        onClickArchiveHandler();
      },
    });
  };

  // if post has been edited, append label to last element
  if (
    journal.edited_at &&
    !journal.body.includes("<span class='edited-label'>")
  ) {
    const match = journal.body.match(/^(.*)(<(?:.*?)>)$/);
    if (match) {
      journal.body =
        match[1] +
        "<span class='edited-label'>(" +
        getI18n().t('label_edited') +
        ')</span>' +
        match[2];
    }
  }

  // image width and height
  const size = {
    thumb: 160,
    preview: 1200,
  };

  return (
    <Translation>
      {(t) => (
        <Card className='journal-summary'>
          {props.profileId === journal.profile.id ||
          (archiveFlag && props.isCareGuideOwner) ? (
            <div className='extra'>
              <Dropdown
                overlay={
                  <Menu>
                    {props.profileId === journal.profile.id ? (
                      <Menu.Item
                        key={'edit-' + journal.id}
                        icon={<EditOutlined />}
                        onClick={onClickEditHandler}
                      >
                        {t('action_edit')}
                      </Menu.Item>
                    ) : null}
                    {archiveFlag && props.isCareGuideOwner ? (
                      <Menu.Item
                        key={'archive-' + journal.id}
                        danger
                        icon={
                          <span className='anticon'>
                            <BiBox />
                          </span>
                        }
                        onClick={confirmArchive}
                      >
                        {t('action_archive')}
                      </Menu.Item>
                    ) : null}
                  </Menu>
                }
                trigger={['click']}
              >
                <span className='anticon' tabIndex={-1}>
                  <FaEllipsisV />
                </span>
              </Dropdown>
            </div>
          ) : null}

          <Card.Meta
            avatar={<UserAvatar profile={journal.profile} showTooltp={false} />}
            title={
              <>
                {journal.profile
                  ? journal.profile.first_name + ' ' + journal.profile.last_name
                  : null}
              </>
            }
            description={
              <>
                <div className='journal-date'>
                  {Format.date(
                    journal.published_at,
                    Config.get('DEFAULT_DATETIME_FORMAT')
                  )}
                </div>
                <RatingDisplay
                  rating={journal.rating}
                  size={isLarge ? 22 : 18}
                />
              </>
            }
          />

          <div
            className='journal-post'
            dangerouslySetInnerHTML={{ __html: journal.body }}
          />

          <JournalEmojisList
            careGuideId={careGuideId}
            journalPostId={journal.id}
            page={1}
            limit={50}
          />

          {journal.tags && journal.tags.length > 0 ? (
            <div className='journal-tags'>
              {journal.tags.map((x) => (
                <Tag key={x.id}>
                  <Link
                    to={QueryString.append(
                      pathTo('JournalPostsScreen', { id: careGuideId }),
                      { tags: `${x.id}.tag` }
                    )}
                  >
                    {x.label}
                  </Link>
                </Tag>
              ))}
            </div>
          ) : null}

          {journal.partner_tags && journal.partner_tags.length > 0 ? (
            <div className='journal-tags'>
              {journal.partner_tags.map((x) => (
                <Tag key={x.id} color={x.partnership.color_hex}>
                  <Link
                    to={QueryString.append(
                      pathTo('JournalPostsScreen', { id: careGuideId }),
                      { tags: `${x.id}.partner_tag` }
                    )}
                  >
                    {x.label}
                  </Link>
                </Tag>
              ))}
            </div>
          ) : null}

          {journal.media && journal.media.length > 0 ? (
            <div className='journal-media'>
              <Image.PreviewGroup>
                {journal.media.map((x) => {
                  if (!x) return null;

                  return (
                    <JournalSignedImage
                      item={x}
                      sizeThumb={size.thumb}
                      sizePreview={size.preview}
                    />
                  );
                })}
              </Image.PreviewGroup>
            </div>
          ) : null}

          <div className='journal-post-replies'>
            <JournalRepliesList
              careGuideId={careGuideId}
              journalPostId={journal.id}
              page={commentsPage}
              limit={5}
              order={'published_at.desc'}
              setPage={setCommentsPage.bind(this)}
            />
          </div>
        </Card>
      )}
    </Translation>
  );
};

export default JournalPostDetail;

Logger.log('silly', `JournalPostDetail loaded.`);
