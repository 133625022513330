import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';

import {
  getImageSignedURL,
  setActiveImage,
} from '../../../../../state/modules/media/actions';
import {
  respondCalendarParticipantEvent,
  calendarHideEventDetail,
  calendarShowEventForm,
} from '../../../../../state/modules/calendar/actions';
import EventModal from '../components/EventModal';
import Format from '../../../../../lib/Format';

const mapStateToProps = (state, ownProps) => {
  const activeId = state.careGuides.get('activeId') ?? ownProps.match.params.id;
  const careGuide = activeId
    ? denormalize(
        state.entities.getIn(['careGuides', activeId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const eventId = state.calendar.get('eventDetailEventId');
  const event = eventId
    ? denormalize(
        state.entities.getIn(['calendarEvents', eventId]),
        schema.calendarEvent,
        state.entities.toJS()
      )
    : null;

  const profileId = state.session.get('profileId');
  let participantId = null;
  if (event && event.participants && event.participants.length > 0) {
    const filteredParticipant = event.participants.filter(
      (x) => x.profile.id === profileId
    );
    if (filteredParticipant.length > 0) {
      participantId = filteredParticipant[0]['id'];
    }
  }

  const logo = event?.partnership?.logo ?? null;
  const size = 400;

  const imagePath = Format.buildImagePath('ratio', size, size, logo);

  const imageUrl = state.media.getIn(['images', imagePath]) ?? null;

  return {
    size: size,
    imagePath: imagePath,
    imageUrl: imageUrl,
    visible: state.calendar.get('eventDetailIsVisible'),
    eventId: eventId,
    event: event,
    view: state.calendar.get('eventDetailIsView'),
    profileId: profileId,
    participantId: participantId,
    careGuide: careGuide,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    respond: (id, data, cb) => {
      dispatch(respondCalendarParticipantEvent(id, data, cb));
    },
    hideDetail: () => {
      dispatch(calendarHideEventDetail());
    },
    showForm: (eventId) => {
      dispatch(calendarShowEventForm(eventId));
    },
    getSignedUrl: (path, cb) => {
      dispatch(getImageSignedURL(path, cb));
    },
    setActiveImage: (imageUrl, imagePath) => {
      dispatch(setActiveImage(imageUrl, imagePath));
    },
  };
};

const EventModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventModal);

export default EventModalContainer;
