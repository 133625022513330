import React, { useState, useEffect, useRef } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Form, Modal, Upload, Typography, Space } from 'antd';

import { FileOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { documentCheck } from '../../../elements/components/Icons';
import Logger from '../../../../../lib/Logger';
import hasPermission, {
  VIEW_PARTNER,
} from '../../../elements/lib/hasPermission';
import notification from '../../../elements/lib/NotificationWrapper';
import ResourceAdminFormHeader from './ResourceFormHeader';
import ResourceFormStep1 from './ResourceFormStep1';
import ResourceFormStep2 from './ResourceFormStep2';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const { Title } = Typography;

const layout = {
  main: {
    labelCol: { span: 23 },
    wrapperCol: { span: 23 },
  },
};

// upload criteria
const fileTypes = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
};
const fileSizeLimit = 1024 * 1024 * 6;
const reFileKey = /(?:(.*?)\/)?(.*?)\.(\w+)/;
const maxCount = 1;

const confirmTexts = {
  title: getI18n().t('resource_form_leave_title'),
  description: getI18n().t('resource_form_leave_description'),
  leaveText: getI18n().t('resource_form_leave_leave'),
  saveText: getI18n().t('resource_form_leave_save'),
};

const ResourceForm = ({
  careGuideId,
  careGuide,
  partnershipId,
  resourceId,
  page,
  limit,
  order,
  tab,
  search,
  serviceTags,
  errors,
  isLoading,
  isSubmitting,
  totalMembers,
  totalMembersFiltered,
  idsFiltered,
  loadTotalMembers,
  loadResource,
  data,
  getSignedUrl,
  ...props
}) => {
  const [form] = Form.useForm();
  const [isPinned, setIsPinned] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isBeforeUpload, setIsBeforeUpload] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [mediaId, setMediaId] = useState(null);
  const [isDeleteMedia, setIsDeleteMedia] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isChangeValue, setIsChangeValue] = useState(false);
  const [value, setValue] = useState('');
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [optionMembers, setOptionMembers] = useState(1);
  const [classes, setClasses] = useState([]);
  const [careGuideIds, setCareGuideIds] = useState(data['care_guides']);
  const [careGuidePartnerTags, setCareGuidePartnerTags] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const dataString = JSON.stringify(data);

  const fileMap = useRef({});

  const nextStep = async () => {
    const values = await form.validateFields();
    form
      .validateFields(['name'])
      .then(async () => {
        setFormData({ ...formData, ...values });
        setStep(step + 1);
      })
      .catch(() => {});
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  const submitDataImage = (
    file,
    values,
    payload,
    resources_care_partner_tags
  ) => {
    props.getUploadUrl(
      file.name.split('.').pop(),
      'logos',
      async (success, upload_url, file_id) => {
        if (success) {
          // upload image
          props.upload(upload_url, file.file, async (uploadSuccess) => {
            const directory = 'logos/';
            file_id = file_id.replace(directory, '');
            if (uploadSuccess) {
              const matches = reFileKey.exec(file_id);
              const updateData = {
                file_directory: directory.slice(0, -1),
                file_name: matches[2],
                file_extension: matches[3],
              };

              if (partnershipId) {
                // process image
                props.createImagePartnership(
                  careGuideId,
                  partnershipId,
                  updateData,
                  (createSuccess, imageId) => {
                    if (createSuccess) {
                      setMediaId(imageId);
                      payload['logo_id'] = imageId;
                      processData(values, payload, resources_care_partner_tags);
                    } else {
                      errorMessage();
                    }
                  }
                );
              } else {
                // process image
                props.createImage(
                  careGuideId,
                  updateData,
                  (createSuccess, imageId) => {
                    if (createSuccess) {
                      setMediaId(imageId);
                      payload['logo_id'] = imageId;
                      processData(values, payload, resources_care_partner_tags);
                    } else {
                      errorMessage();
                    }
                  }
                );
              }
            } else {
              errorMessage();
            }
          });
        } else {
          errorMessage();
        }
      }
    );
  };

  const processData = (values, payload, resources_care_partner_tags) => {
    let valuesForm = { ...formData, ...values };

    // transform values
    for (const key of Object.keys(data)) {
      // remove any non digit characters from strings

      if (['telephone'].includes(key)) {
        if (valuesForm[key]) {
          payload[key] = valuesForm[key].replace(/\D/g, '');
        }
      } else if (['service_tags'].includes(key)) {
        payload['service_tag_ids'] = valuesForm[key];
      } else if (['is_pinned'].includes(key)) {
        payload[key] = valuesForm[key] ? true : false;
      } else if (['is_favorite'].includes(key)) {
        payload[key] = valuesForm[key] ? true : false;
      } else if (['care_guides'].includes(key)) {
        payload['care_guide_ids'] = valuesForm[key];
      } else if (['website', 'email', 'postal_code'].includes(key)) {
        if (valuesForm[key]) {
          payload[key] = valuesForm[key];
        }
      } else if (key in valuesForm) {
        payload[key] = valuesForm[key];
      }
    }

    payload['is_multiple_care_guides'] = false;

    if (optionMembers === 1 && step === 1) {
      payload['is_multiple_care_guides'] = true;
    }

    for (const input of classes) {
      if (valuesForm[input]) {
        if (Array.isArray(valuesForm[input])) {
          const tagsFilters = valuesForm[input].filter((item) => item !== '');
          resources_care_partner_tags =
            resources_care_partner_tags.concat(tagsFilters);
        } else {
          if (valuesForm[input] !== '') {
            resources_care_partner_tags.push(valuesForm[input]);
          }
        }
      }
    }

    payload['resources_care_partner_tags_list'] = resources_care_partner_tags;

    if (partnershipId && hasPermission(VIEW_PARTNER)) {
      payload['partnership_id'] = partnershipId;
    }

    const filter = {};
    if (tab) filter[tab] = true;
    if (search !== '') filter.search = search;
    if (serviceTags) filter.service_tags = serviceTags;

    delete payload['logo'];
    delete payload['resources_care_partner_tags'];

    if (resourceId) {
      // update
      props.update(careGuideId, resourceId, payload, async (success) => {
        if (success) {
          if (isDeleteMedia) {
            if (partnershipId) {
              await props.deleteImagePartnership(partnershipId, deleteId);
            } else {
              await props.deleteImage(careGuideId, deleteId);
            }
          }

          if (careGuide.partnership) {
            MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.EDIT, {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              partnershipName: careGuide.partnership.name,
              careGuideId: careGuide.id,
              partnershipId: careGuide.partnership.id,
            });
          } else {
            MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.EDIT, {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              careGuideId: careGuide.id,
            });
          }

          notification.success(getI18n().t('feedback_form_success'));
          clearForm();
          props.load(careGuideId, page, limit, order, filter);
        } else {
          notification.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(careGuideId, payload, (success) => {
        if (success) {
          if (careGuide.partnership) {
            MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.CREATE, {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              partnershipName: careGuide.partnership.name,
              careGuideId: careGuide.id,
              partnershipId: careGuide.partnership.id,
            });
          } else {
            MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.CREATE, {
              careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
              careGuideId: careGuide.id,
            });
          }
          notification.success(getI18n().t('feedback_form_success'));
          clearForm();
          props.load(careGuideId, page, limit, order, filter);
        } else {
          notification.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `ResourceForm.submitData(###)`);
    const payload = {};
    let resources_care_partner_tags = [];

    if (uploadedFile !== null) {
      if (mediaId) {
        payload['logo_id'] = mediaId;
        processData(values, payload, resources_care_partner_tags);
      } else {
        const file = uploadedFile[0];
        submitDataImage(file, values, payload, resources_care_partner_tags);
      }
    } else {
      payload['logo_id'] = null;
      processData(values, payload, resources_care_partner_tags);
    }
  };

  const leaveModal = () => {
    if (isChangeValue) {
      setConfirmationModalOpen(true);
    } else {
      clearForm();
    }
  };

  const clearForm = async () => {
    props.hideForm();
    props.formDestroy();
    form.setFieldsValue({
      name: '',
      description: '',
      telephone: null,
      website: '',
      email: '',
      postal_code: '',
      service_tags: [],
      is_event_partner: false,
      is_pinned: false,
      is_favorite: false,
      care_guides: [],
      logo: null,
      resources_care_partner_tags: [],
    });
    for (const key of Object.keys(data)) {
      form.setFields([{ name: key, errors: [] }]);
    }
    fileMap.current = {};

    setIsBeforeUpload(true);
    setMediaId(null);

    setUploadedFile(null);
    setIsBeforeUpload(false);
    setMediaId(null);
    setIsChangeValue(false);
    setStep(0);
    for (const input of classes) {
      form.setFieldsValue({ [input]: [] });
    }
    setClasses([]);
    setCareGuideIds([]);
    setCareGuidePartnerTags([]);
    setOptionMembers(1);
    setValue('');
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `ResourceForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `ResourceForm.handleFinishFailed(###)`);
    notification.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // remove error message when input value changes
  const handleValuesChange = (changedValues, allValues) => {
    setIsChangeValue(true);
    const nameValues = Object.keys(changedValues);
    for (const key of nameValues) {
      form.setFields([{ name: key, errors: [] }]);
    }
    let resources_care_partner_tags = [];
    if (optionMembers === 2 && step === 1) {
      for (const input of classes) {
        if (form.getFieldsValue()[input]) {
          if (Array.isArray(form.getFieldsValue()[input])) {
            const tagsFilters = form
              .getFieldsValue()
              [input].filter((item) => item !== '');
            resources_care_partner_tags =
              resources_care_partner_tags.concat(tagsFilters);
          } else {
            if (form.getFieldsValue()[input] !== '') {
              resources_care_partner_tags.push(form.getFieldsValue()[input]);
            }
          }
        }
      }
      const filter = {
        care_guide_partner_tags: resources_care_partner_tags,
      };
      setCareGuidePartnerTags(resources_care_partner_tags);
      if (resources_care_partner_tags.length > 0) {
        loadTotalMembers(partnershipId, filter);
      } else {
        loadTotalMembers(partnershipId, {});
      }
    }
  };

  const onClickOK = () => {
    form.submit();
  };

  const errorMessage = () => {
    notification.error(
      getI18n().t('resource_form_feedback_image_upload_error')
    );
  };

  const onBeforeUpload = (file) => {
    const isUnderMaxCount = Object.keys(fileMap.current).length < maxCount;
    if (!isUnderMaxCount) {
      form.setFields([
        {
          name: 'doc-file',
          errors: [
            <Space>
              <ExclamationCircleOutlined />
              {getI18n().t('resource_form_feedback_image_file_types')}
            </Space>,
          ],
        },
      ]);

      setUploadedFile(null);
      return Upload.LIST_IGNORE;
    }

    const isSupportedFileType = Object.keys(fileTypes).includes(file.type);
    if (!isSupportedFileType) {
      form.setFields([
        {
          name: 'doc-file',
          errors: [
            <Space>
              <ExclamationCircleOutlined />
              {getI18n().t('resource_form_feedback_image_file_types')}
            </Space>,
          ],
        },
      ]);

      setUploadedFile(null);
      return Upload.LIST_IGNORE;
    }

    const isUnderFileSizeLimit = file.size < fileSizeLimit;
    if (!isUnderFileSizeLimit) {
      form.setFields([
        {
          name: 'doc-file',
          errors: [
            <Space>
              <ExclamationCircleOutlined />
              {getI18n().t('resource_form_feedback_image_file_size_limit')}
            </Space>,
          ],
        },
      ]);

      setUploadedFile(null);
      return Upload.LIST_IGNORE;
    }

    if (isSupportedFileType && isUnderFileSizeLimit) {
      setIsBeforeUpload(true);
      setMediaId(null);
      return false;
    }
    return Upload.LIST_IGNORE;
  };

  const handleFileChange = (info) => {
    if (info.file.status === 'removed') {
      setUploadedFile(null);
      setIsBeforeUpload(false);
      if (mediaId) {
        setIsDeleteMedia(true);
        setDeleteId(mediaId);
        setMediaId(null);
      }
    } else {
      const file = {
        ...info.fileList[0],
        file: info.file,
        thumbUrl: URL.createObjectURL(info.fileList[0].originFileObj),
      };

      setUploadedFile([file]);
      form.setFieldsValue({
        'doc-file': file,
      });
    }
  };

  const uploadIcon = () => {
    return isBeforeUpload ? documentCheck() : <FileOutlined />;
  };

  const onChangePinned = (e) => {
    setIsPinned(e.target.checked);
    setIsChangeValue(true);
  };

  const onChangeFavorite = (e) => {
    setIsFavorite(e.target.checked);
    setIsChangeValue(true);
  };

  const onHandleChangeOptionMembers = (e) => {
    setOptionMembers(e.target.value);
    if (e.target.value === 1) {
      loadTotalMembers(partnershipId, {});
    } else {
      if (careGuidePartnerTags.length > 0) {
        loadTotalMembers(partnershipId, {
          care_guide_partner_tags: careGuidePartnerTags,
        });
      }
    }
  };

  const handleConfirmLeave = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmClose = () => {
    clearForm();
    setConfirmationModalOpen(false);
  };

  useEffect(() => {
    if (resourceId && !isSubmitting) {
      const dataObj = JSON.parse(dataString);
      setIsPinned(dataObj['is_pinned']);
      setIsFavorite(dataObj['is_favorite']);
      setValue(dataObj['description']);
      const transformedTags = dataObj['resources_care_partner_tags'].reduce(
        (acc, item) => {
          const className = item.admin_partner_class.name;
          const idValue = item.id;
          if (acc[className]) {
            acc[className].push(idValue);
          } else {
            acc[className] = [idValue];
          }

          return acc;
        },
        {}
      );
      form.setFieldsValue({
        ...dataObj,
        ...transformedTags,
      });

      setCareGuideIds(dataObj['care_guides']);

      if (dataObj['care_guides'].length > 0) {
        setOptionMembers(2);
      } else if (Object.keys(transformedTags).length > 0) {
        setOptionMembers(2);
      }

      if (dataObj['logo']) {
        setMediaId(dataObj['logo']['id']);
        form.setFieldsValue({
          'doc-file': 'preloaded',
        });

        if (
          dataObj['logo'] &&
          dataObj['logo']['file_directory'] &&
          dataObj['logo']['file_name'] &&
          dataObj['logo']['file_extension']
        ) {
          const size = 160;
          const path = `ratio/${size}x${size}/${dataObj['logo']['file_directory']}/${dataObj['logo']['file_name']}.${dataObj['logo']['file_extension']}`;

          getSignedUrl(path, (success, signedUrl) => {
            if (success) {
              const defaultFile = [
                {
                  uid: dataObj['logo']['id'],
                  name: dataObj['logo']['file_name'],
                  url: signedUrl,
                  thumbUrl: signedUrl,
                  status: 'done',
                },
              ];
              setUploadedFile(defaultFile);
              setIsBeforeUpload(true);
            }
          });
        }
      }
      setCareGuidePartnerTags(Object.values(transformedTags).flat());
    }
  }, [resourceId, form, isSubmitting, dataString]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (careGuidePartnerTags.length > 0) {
      loadTotalMembers(partnershipId, {
        care_guide_partner_tags: careGuidePartnerTags,
      });
    } else {
      loadTotalMembers(partnershipId, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careGuidePartnerTags]);

  // load record data from API
  useEffect(() => {
    if (resourceId) {
      loadResource(careGuideId, resourceId);
    }
  }, [careGuideId, resourceId, loadResource]);

  useEffect(() => {
    if (!resourceId && props.visible) {
      setCareGuideIds(data['care_guides']);
      form.setFieldsValue({
        care_guides: data['care_guides'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              <Title level={3} className='resource-note-modal-title'>
                {resourceId
                  ? t('resource_form_title_edit')
                  : t('resource_form_title_create')}
              </Title>
            }
            centered
            visible={props.visible}
            onOk={onClickOK}
            onCancel={leaveModal}
            footer={[
              step === 0 && (
                <Button
                  key='cancel'
                  size='large'
                  className='resource-form-btn'
                  onClick={leaveModal}
                >
                  {t('resource_form_button_cancel')}
                </Button>
              ),

              hasPermission(VIEW_PARTNER) && step === 1 && (
                <>
                  <Button
                    key='cancel'
                    size='large'
                    className='resource-form-btn'
                    onClick={leaveModal}
                  >
                    {t('resource_form_button_cancel')}
                  </Button>
                  <Button
                    size='large'
                    className='resource-form-btn'
                    onClick={prevStep}
                  >
                    {t('resource_form_button_previous_step')}
                  </Button>
                </>
              ),

              hasPermission(VIEW_PARTNER) && step === 0 && (
                <Button
                  type='primary'
                  size='large'
                  loading={isSubmitting}
                  onClick={nextStep}
                >
                  {t('resource_form_button_next_step')}
                </Button>
              ),

              ((hasPermission(VIEW_PARTNER) && step === 1) ||
                !hasPermission(VIEW_PARTNER)) && (
                <Button
                  disabled={!isChangeValue}
                  key='submit'
                  type='primary'
                  size='large'
                  loading={isSubmitting}
                  onClick={onClickOK}
                >
                  {resourceId
                    ? t('resource_form_button_edit_submit')
                    : t('resource_form_button_add_submit')}
                </Button>
              ),
            ]}
            className='resource-form-modal'
            width={1100}
            forceRender={true}
          >
            <div className='resource-form'>
              {hasPermission(VIEW_PARTNER) && (
                <ResourceAdminFormHeader id={resourceId} step={step} />
              )}
              <Form
                name='resource_form'
                form={form}
                initialValues={data}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
                onValuesChange={handleValuesChange}
                validateTrigger='onSubmit'
                layout='vertical'
                {...layout.main}
              >
                {step === 0 && (
                  <ResourceFormStep1
                    form={form}
                    data={data}
                    handleFinish={handleFinish}
                    handleFinishFailed={handleFinishFailed}
                    isBeforeUpload={isBeforeUpload}
                    uploadIcon={uploadIcon}
                    handleFileChange={handleFileChange}
                    uploadedFile={uploadedFile}
                    onBeforeUpload={onBeforeUpload}
                    isPinned={isPinned}
                    setIsPinned={setIsPinned}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    value={value}
                    setValue={setValue}
                    isChangeValue={isChangeValue}
                    setIsChangeValue={setIsChangeValue}
                    handleValuesChange={handleValuesChange}
                    isFavorite={isFavorite}
                    onChangeFavorite={onChangeFavorite}
                    onChangePinned={onChangePinned}
                  />
                )}

                {step === 1 && (
                  <ResourceFormStep2
                    form={form}
                    optionMembers={optionMembers}
                    partnershipId={partnershipId}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    totalMembers={totalMembers}
                    totalMembersFiltered={totalMembersFiltered}
                    classes={classes}
                    setClasses={setClasses}
                    setCareGuideIds={setCareGuideIds}
                    onHandleChangeOptionMembers={onHandleChangeOptionMembers}
                    careGuidePartnerTags={careGuidePartnerTags}
                    idsFiltered={idsFiltered}
                    careGuideIds={careGuideIds}
                  />
                )}

                <div style={{ display: 'none' }}>
                  <Button htmlType='submit'></Button>
                </div>
              </Form>

              <ConfirmationModal
                texts={confirmTexts}
                openConfirmationModal={confirmationModalOpen}
                onLeaveConfirmation={handleConfirmClose}
                onSaveConfirmation={handleConfirmLeave}
                onCancel={handleConfirmLeave}
                setConfirmationModalOpen={setConfirmationModalOpen}
              />
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default ResourceForm;

Logger.log('silly', `ResourceForm loaded.`);
