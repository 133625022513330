import React, { useState, useRef, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Col, Tag, Row, Button, Space, Divider, Typography } from 'antd';
import { TelephoneDisplayCard } from '../../../elements/components/TelephoneDisplay';
import { WebsiteDisplayCard } from '../../../elements/components/WebsiteDisplay';
import { EmailDisplayCard } from '../../../elements/components/EmailDisplay';
import ReactQuill from 'react-quill';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import notification from '../../../elements/lib/NotificationWrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pinFilled } from '../../../elements/components/Icons';
import ResourceCardActions from './ResourceCardActions';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';

import hasPermission, {
  VIEW_PARTNER,
  VIEW_OWNER,
} from '../../../elements/lib/hasPermission';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';
import useFetchSignedUrl from '../../../hooks/useFetchSignedUrl';

const { Title } = Typography;

const modules = {
  toolbar: '#toolbar-read-only-resource',
};

const types = {
  link: 'link',
  phone: 'phone',
  email: 'email',
};

const ResourceCardHeader = ({
  item,
  careGuideId,
  partnershipId,
  handleFavorite,
  handlePinned,
  handleDisabled,
  handleRemove,
  handleDownloadCSV,
  handleDownloadPDF,
  isDownload,
  showForm,
  profileId,
}) => {
  return (
    <Translation>
      {(t) => (
        <Row align='top' justify='space-between' style={{ height: '26px' }}>
          <Col>
            <Space
              onClick={
                !hasPermission(VIEW_PARTNER)
                  ? (e) => {
                      e.stopPropagation();
                      handleFavorite(careGuideId, item, item.is_favorite);
                    }
                  : undefined
              }
              className={`cursor-pointer ${
                item.is_favorite ? 'icon-active' : 'icon-inactive'
              }`}
            >
              {item.is_favorite ? (
                <>
                  <FontAwesomeIcon icon={fasStar} />
                  {t('resource_card_header_favorite')}
                </>
              ) : (
                <>
                  {!hasPermission(VIEW_PARTNER) && (
                    <>
                      <FontAwesomeIcon icon={farStar} />
                      {t('resource_card_header_favorite')}
                    </>
                  )}
                </>
              )}
            </Space>
          </Col>
          <Col>
            <Space
              className={`${
                hasPermission(VIEW_PARTNER) &&
                item?.partnership?.id &&
                'cursor-pointer'
              } ${item.is_pinned && 'icon-active'}`}
              onClick={
                hasPermission(VIEW_PARTNER) &&
                item?.partnership?.id &&
                ((e) => {
                  e.stopPropagation();
                  handlePinned(careGuideId, item, item.is_pinned);
                })
              }
            >
              {item.is_pinned && pinFilled(12, 15)}
            </Space>
            {((item?.partnership && hasPermission(VIEW_PARTNER)) ||
              item?.profile?.id === profileId ||
              (hasPermission(VIEW_OWNER) &&
                !item?.partnership &&
                !item.is_universal)) && (
              <ResourceCardActions
                profileId={profileId}
                handlePinned={handlePinned}
                handleRemove={handleRemove}
                handleDisabled={handleDisabled}
                handleDownloadCSV={handleDownloadCSV}
                handleDownloadPDF={handleDownloadPDF}
                isDownload={isDownload}
                showForm={showForm}
                careGuideId={careGuideId}
                partnershipId={partnershipId}
                item={item}
              />
            )}
          </Col>
        </Row>
      )}
    </Translation>
  );
};

const ResourceCardFotter = ({ item, showList, trackerResource }) => {
  return (
    <Translation>
      {(t) => (
        <Row align='top' justify='space-between'>
          <Col>
            {(!hasPermission(VIEW_PARTNER) ||
              (hasPermission(VIEW_PARTNER) && item?.partnership?.id)) && (
              <Button
                size='small'
                style={{ marginTop: '10px' }}
                icon={
                  <span className='anticon'>
                    {hasPermission(VIEW_PARTNER) ? (
                      <EyeOutlined />
                    ) : (
                      <PlusOutlined />
                    )}
                  </span>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  trackerResource(item, mixpanelEventsEnum.RESOURCE.OPEN_NOTES);
                  showList(item.id);
                }}
              >
                {t('resource_notes_button_notes')}
              </Button>
            )}
          </Col>
        </Row>
      )}
    </Translation>
  );
};

const ResourceCard = ({
  item,
  careGuideId,
  careGuide,
  patchFavorite,
  showDetail,
  remove,
  patch,
  profileId,
  load,
  page,
  limit,
  order,
  tab,
  search,
  serviceTags,
  showList,
  showForm,
  createInteraction,
  downloadCSV,
  downloadPDF,
  isDownload,
  partnershipId,
  partnershipName,
  getSignedUrl,
  imageUrl,
  setActiveImage,
  size,
  imagePath,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [applyLineClamp, setApplyLineClamp] = useState(false);
  const textRef = useRef(null);
  const hoverTimeout = useRef(null);

  const trackerResource = (item, event, params = {}) => {
    if (careGuide?.partnership) {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        partnershipName: careGuide.partnership.name,
        careGuideId: careGuide.id,
        partnershipId: careGuide.partnership.id,
        resourceId: item.id,
        resourceName: item.name,
        ...params,
      });
    } else {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        careGuideId: careGuide.id,
        resourceId: item.id,
        resourceName: item.name,
        ...params,
      });
    }
  };

  const checkAndApplyLineClamp = () => {
    if (textRef.current && isHovered) {
      const lineHeight = 43;
      const exceedsOneLine = textRef.current.clientHeight > lineHeight;
      setApplyLineClamp(exceedsOneLine);
    } else {
      setApplyLineClamp(false);
    }
  };
  useEffect(() => {
    checkAndApplyLineClamp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);

  const handleFavorite = (careGuideId, item, is_favorite) => {
    patchFavorite(
      careGuideId,
      item.id,
      { is_favorite: !is_favorite },
      (success) => {
        const filter = {};
        if (tab) filter[tab] = true;
        if (search !== '') filter.search = search;
        if (serviceTags) filter.service_tags = serviceTags;

        if (success) {
          load(careGuideId, page, limit, order, filter);
          if (!is_favorite) {
            trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_FAVORITE, {
              state: 'Favorite',
            });
            notification.success(
              getI18n().t('resource_card_button_favorite_success')
            );
          } else {
            trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_FAVORITE, {
              state: 'Unfavorite',
            });
            notification.success(
              getI18n().t('resource_card_button_unfavorite_success')
            );
          }
        } else {
          notification.error(
            getI18n().t('resource_card_button_favorite_failure')
          );
        }
      }
    );
  };

  const handlePinned = (careGuideId, item, is_pinned) => {
    const filter = {};
    if (tab) filter[tab] = true;
    if (search !== '') filter.search = search;
    if (serviceTags) filter.service_tags = serviceTags;

    patch(careGuideId, item.id, { is_pinned: !is_pinned }, (success) => {
      if (success) {
        load(careGuideId, page, limit, order, filter);
        if (!is_pinned) {
          trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_PINNED, {
            state: 'Pinned',
          });
          notification.success(
            getI18n().t('resource_form_button_update_pinned_success')
          );
        } else {
          trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_PINNED, {
            state: 'Unpinned',
          });
          notification.success(
            getI18n().t('resource_form_button_update_unpinned_success')
          );
        }
      } else {
        notification.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  const handleDisabled = (careGuideId, item, status) => {
    const filter = {};
    if (tab) filter[tab] = true;
    if (search !== '') filter.search = search;
    if (serviceTags) filter.service_tags = serviceTags;

    patch(careGuideId, item.id, { status: status }, (success) => {
      if (success) {
        load(careGuideId, page, limit, order, filter);
        if (status === 1) {
          trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_DISABLE, {
            state: 'Enabled',
          });
          notification.success(
            getI18n().t('resource_form_button_update_enabled_success')
          );
        } else {
          trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_DISABLE, {
            state: 'Disabled',
          });
          notification.success(
            getI18n().t('resource_form_button_update_disabled_success')
          );
        }
      } else {
        notification.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  const handleRemove = (careGuideId, item) => {
    const filter = {};
    if (tab) filter[tab] = true;
    if (search !== '') filter.search = search;
    if (serviceTags) filter.service_tags = serviceTags;

    remove(careGuideId, item.id, (success) => {
      if (success) {
        trackerResource(item, mixpanelEventsEnum.RESOURCE.DELETE);
        load(careGuideId, page, limit, order, filter);
        notification.success(getI18n().t('feedback_delete_success'));
      } else {
        notification.error(getI18n().t('feedback_delete_error'));
      }
    });
  };

  const confirmShowPreview = (event, item) => {
    trackerResource(item, mixpanelEventsEnum.RESOURCE.OPEN_PREVIEW);
    event.preventDefault();
    showDetail(item.id);
  };

  const onClickLinks = (event, careGuideId, item, type) => {
    event.stopPropagation();
    trackerResource(item, mixpanelEventsEnum.RESOURCE.OPEN_LINK, {
      linkType: types[type],
    });
    createInteraction(careGuideId, item.id, { [types[type]]: true });
  };

  const handleDownloadCSV = (partnershipId, item) => {
    downloadCSV(partnershipId, partnershipName, item.id, (success) => {
      if (success) {
        trackerResource(item, mixpanelEventsEnum.RESOURCE.EXPORT_CSV);
        notification.success(
          getI18n().t('resource_card_action_download_success')
        );
      } else {
        notification.error(
          getI18n().t('resource_card_action_download_failure')
        );
      }
    });
  };

  const handleDownloadPDF = (partnershipId, item) => {
    downloadPDF(partnershipId, partnershipName, item.id, (success) => {
      if (success) {
        trackerResource(item, mixpanelEventsEnum.RESOURCE.EXPORT_PDF);
        notification.success(
          getI18n().t('resource_card_action_download_success')
        );
      } else {
        notification.error(
          getI18n().t('resource_card_action_download_failure')
        );
      }
    });
  };

  const showTags = (tags) => {
    if (tags.length > 4) {
      return (
        <>
          {tags.slice(0, 4).map((x, i) => (
            <Tag color={x.color_hex} key={i}>
              {x.label}
            </Tag>
          ))}
          <Tag color={'magenta'}>{'+' + (tags.length - 4)}</Tag>
        </>
      );
    } else {
      return tags.map((x, i) => (
        <Tag color={x.color_hex} key={i}>
          {x.label}
        </Tag>
      ));
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout.current);
    hoverTimeout.current = setTimeout(() => {
      setIsHovered(true);
    }, 50);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout.current);
    hoverTimeout.current = setTimeout(() => {
      setIsHovered(false);
    }, 50);
  };

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout.current);
    };
  }, []);

  useFetchSignedUrl(imagePath, imageUrl, getSignedUrl, setActiveImage);

  return (
    <Translation>
      {(t) => (
        <div
          onClick={(e) => confirmShowPreview(e, item)}
          className={`resource-card ${item.status === 2 && 'disabled'}`}
          style={{
            marginTop: '6px',
            height: '400px',
            border: item?.partnership
              ? `2px solid ${item?.partnership.color_hex}`
              : item.is_universal
              ? '2px solid #1A2E5F'
              : null,
            cursor: 'pointer',
          }}
        >
          <ResourceCardHeader
            profileId={profileId}
            item={item}
            careGuideId={careGuideId}
            partnershipId={partnershipId}
            handleFavorite={handleFavorite.bind(this)}
            handlePinned={handlePinned.bind(this)}
            handleRemove={handleRemove.bind(this)}
            handleDisabled={handleDisabled.bind(this)}
            handleDownloadCSV={handleDownloadCSV.bind(this)}
            handleDownloadPDF={handleDownloadPDF.bind(this)}
            isDownload={isDownload}
            showForm={showForm}
          />
          <Row align='top' justify='space-between' style={{ marginTop: '8px' }}>
            <Col
              xs={{ span: item?.logo ? 16 : 24, order: 1 }}
              md={{ span: item?.logo ? 16 : 24, order: 1 }}
              lg={{ span: item?.logo ? 16 : 24, order: 1 }}
              xl={{ span: item?.logo ? 16 : 24, order: 1 }}
            >
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={textRef}
              >
                <Title className={'resource-card-title'} level={3}>
                  {item.name}
                </Title>
              </div>
            </Col>
            <Col
              xs={{ span: 8, order: 2 }}
              md={{ span: 8, order: 2 }}
              lg={{ span: 8, order: 2 }}
              xl={{ span: 8, order: 2 }}
            >
              <div className={'resources-card-image'}>
                {imageUrl && (
                  <figure>
                    <img style={{ maxWidth: '100%' }} src={imageUrl} alt='' />
                  </figure>
                )}
              </div>
            </Col>
          </Row>

          <Row align='middle'>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              {!item.is_universal ? (
                item?.partnership ? (
                  <Tag
                    color={item.partnership?.color_hex}
                    className='cursor-pointer'
                  >
                    {item.partnership?.name}
                  </Tag>
                ) : (
                  <Tag color='#FE971F' className='cursor-pointer'>
                    {t('resource_notes_tag_custom_resource')}
                  </Tag>
                )
              ) : (
                <Tag color={'#1A2E5F'} className='cursor-pointer'>
                  {t('resource_notes_tag_general_resource')}
                </Tag>
              )}
            </Col>
          </Row>

          <Divider style={{ margin: '8px 0 8px 0' }} />

          {item?.service_tags
            ? item?.service_tags?.length > 0 && (
                <div className='resource-list-tag-card'>
                  {showTags(item?.service_tags)}
                </div>
              )
            : null}

          {item?.description &&
            item?.description !== '<p><br></p>' &&
            item?.description.length > 0 && (
              <div
                className={`resource-list-description ${
                  applyLineClamp ? 'resource-list-description-expanded' : ''
                }`}
              >
                <div id='toolbar-read-only-resource'></div>
                <ReactQuill
                  modules={modules}
                  readOnly
                  value={item.description}
                />
              </div>
            )}
          {(item?.website || item?.telephone || item?.email) && (
            <div className='info-container'>
              {item?.website ? (
                <WebsiteDisplayCard
                  website={item.website}
                  onClick={(e) => onClickLinks(e, careGuideId, item, 'link')}
                />
              ) : null}
              {item?.telephone ? (
                <TelephoneDisplayCard
                  telephone={item.telephone}
                  onClick={(e) => onClickLinks(e, careGuideId, item, 'phone')}
                />
              ) : null}
              {item?.email ? (
                <EmailDisplayCard
                  email={item.email}
                  onClick={(e) => onClickLinks(e, careGuideId, item, 'email')}
                />
              ) : null}
            </div>
          )}
          <ResourceCardFotter
            item={item}
            showList={showList}
            trackerResource={trackerResource}
          />
        </div>
      )}
    </Translation>
  );
};

export default ResourceCard;
