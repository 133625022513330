import { Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isImageUploadUrlLoading: false,
  isImageSignedUrlLoading: false,
  isImageUploading: false,
  upload: {},
  form: {},
  isLoading: false,
});

export default function media(state = initialState, action) {
  Logger.log('debug', `[state.media.reducers] media(%j, %j)`, state, action);

  switch (action.type) {
    case TYPES.SET_ACTIVE_IMAGE:
      return state
        .mergeDeep({
          imagePath: action.imagePath,
        })
        .setIn(['images', action.imagePath], action.imageUrl);

    case TYPES.IMAGE_UPLOAD_URL_REQUEST:
      return state.mergeDeep({
        isImageUploadUrlLoading: true,
        upload: {},
      });

    case TYPES.IMAGE_UPLOAD_URL_SUCCESS:
      return state.mergeDeep({
        isImageUploadUrlLoading: false,
        upload: {
          upload_url: action.upload_url,
          file_key: action.file_key,
        },
      });

    case TYPES.IMAGE_UPLOAD_URL_FAILURE:
      return state.mergeDeep({
        isImageUploadUrlLoading: false,
        upload: {},
      });

    case TYPES.IMAGE_SIGNED_URL_REQUEST:
      return state.mergeDeep({
        isImageSignedUrlLoading: true,
      });

    case TYPES.IMAGE_SIGNED_URL_SUCCESS:
      return state.mergeDeep({
        isImageSignedUrlLoading: false,
        signed_url: action.signed_url,
      });

    case TYPES.IMAGE_SIGNED_URL_FAILURE:
      return state.mergeDeep({
        isImageSignedUrlLoading: false,
      });

    case TYPES.IMAGE_UPLOAD_REQUEST:
      return state.mergeDeep({
        isImageUploading: true,
      });

    case TYPES.IMAGE_UPLOAD_SUCCESS:
      return state.mergeDeep({
        isImageUploading: false,
      });

    case TYPES.IMAGE_UPLOAD_FAILURE:
      return state.mergeDeep({
        isImageUploading: false,
      });

    case TYPES.CREATE_IMAGE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_IMAGE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_IMAGE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.CREATE_IMAGE_PARTNERSHIP_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_IMAGE_PARTNERSHIP_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_IMAGE_PARTNERSHIP_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.DELETE_IMAGE_REQUEST:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_IMAGE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DELETE_IMAGE_FAILURE:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_REQUEST:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DELETE_IMAGE_CARE_GUIDE_PARTNERSHIP_FAILURE:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case TYPES.DELETE_IMAGE_PARTNERSHIP_REQUEST:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_IMAGE_PARTNERSHIP_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DELETE_IMAGE_PARTNERSHIP_FAILURE:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.media.reducers loaded.`);
